import React, {useEffect, useState, useRef} from 'react';

import Spacer from 'common/components/Spacer/Spacer';
import MultiRangeSlider from './components/RangeSlider/MultiRangeSlider';
import theme from 'theme/theme';

import Tip from 'common/components/Tip/Tip';
import Typography from 'common/components/Typography/Typography';
import './Player.css';

import {SongDataType} from '../../../types';

import {
  BitSelector,
  BitsSelectorContainer,
  BitsSelectorInput,
  CustomSpacer,
  //InfoButton,
  PlayerContainer,
  QuestionMarkButton,
  SelectedInfo,
  SlidersContainer,
  StrokeSpacer,
  StrokesContainer,
  TextContent,
  WhitePlayerStroke,
  YellowPlayerStroke,
  SelectedInfoRow,
  PreDeterminedButtonContainer,
  BuyButton,
  PresetButton,
  PresetText,
} from './Player.styled';
import {useStoreState} from 'store/store';
import {InstractionalStages} from 'typings/types';
import {useViewport} from 'use-viewport';
import SocialShareSection from '../SongInfo/components/SocialShareSection';
import {xs} from 'utils/constants';

type PlayerProps = {
  charity: boolean;
  secondsSelected: number;
  costPerBitDAI: number;
  purchaseModal: () => void;
  setSecondsSelected: (e: number) => void;
  songData: SongDataType;
  limit: number;
  onSelectedDataChange: ({
                           numSelectedBits,
                         }: {
    numSelectedBits: number;
  }) => void;
};

const Player: React.FC<PlayerProps> = ({
                                         costPerBitDAI,
                                         purchaseModal,
                                         songData,
                                         limit,
                                         onSelectedDataChange,
                                         charity
                                       }) => {
  costPerBitDAI
  const viewport = useViewport();

  const [numSelectedBits, setNumSelectedBits] = useState<number>(
    Math.floor(limit),
  );
  const [bitSelectorValue] = useState<string>(``);
  const [sliderInitialised, setSliderInitialised] = useState<boolean>(false);

  const [playerStrokes, setPlayerStrokes] = useState<JSX.Element[]>([]);
  const strokeContainerRef = useRef<any>(null);

  const [leftSliderValue, setLeftSliderValue] = useState<number>(0);
  const [rightSliderValue, setRightSliderValue] = useState<number>(
    Math.floor(limit),
  );

  const [sliderBorderAdjust, setSliderBorderAdjust] = useState<number>(0);

  // Offsets for colouring waveform strokes
  const [strokeColorOffsetLeft, setStrokeColorOffsetLeft] = useState<number>(0);
  const [strokeColorOffsetRight, setStrokeColorOffsetRight] =
    useState<number>(0);

  // Offsets for colouring waveform strokes after BitInput entry
  const [strokeColorOffsetRightBitEntry, setStrokeColorOffsetRightBitEntry] =
    useState<number>(0);

  //BitSelecor position
  const [bitSelectorStart, setBitSelectorStart] = useState<number>(0);
  const [bitSelectorAdjust, setBitSelectorAdjust] = useState<number>(0);

  const bitSelector = document.getElementById('bit-selector');
  const sliderTrack = document.getElementById('sliderTrack');
  const selectedAreaBorderTop = document.getElementById('areaBorderTop');
  const selectedAreaBorderBottom = document.getElementById('areaBorderBottom');
  const leftSlider = document.getElementById('leftSlider');
  const rightSlider = document.getElementById('rightSlider');
  const sliderSelectedRange = document.getElementById('sliderSelectedRange');

  const instructionalStage = useStoreState((state) => state.instructions.stage);

  const generatePlayerStroke = (size: string, strokeNumber: number) => {
    return (
      <div key={`stroke ${strokeNumber}`} style={{marginRight: '10px'}}>
        <YellowPlayerStroke className={size}/>
        <Spacer height={10}/>
        <WhitePlayerStroke className={size}/>
      </div>
    );
  };

  function updatePlayerStrokes(leftThumbPos?: number, rightThumbPos?: number) {
    if (sliderSelectedRange && sliderTrack) {
      const totalUnselectedArea =
        sliderTrack.offsetWidth - sliderSelectedRange.offsetWidth;

      const unselectedAreaPerSide = totalUnselectedArea / 2;

      const sliderRightXCoord = sliderTrack.getBoundingClientRect().right;

      if (leftThumbPos && rightThumbPos) {
        colorPlayerStrokes(leftThumbPos, rightThumbPos);
      } else {
        colorPlayerStrokes(
          sliderTrack.offsetLeft + unselectedAreaPerSide,
          sliderRightXCoord - unselectedAreaPerSide,
        );
      }
    }
  }

  function colorPlayerStrokes(sliderLeftPos: number, sliderRightPos: number) {
    if (!strokeContainerRef.current) {
      return;
    }
    const strokes = [...strokeContainerRef.current.children];
    if (!strokes.length) {
      return;
    }

    strokes.forEach(function (s) {
      if (
        s.offsetLeft < sliderLeftPos + strokeColorOffsetLeft ||
        s.offsetLeft > sliderRightPos + strokeColorOffsetRight
      ) {
        s.children[0].style.background = '#333';
        s.children[2].style.background = '#333';
      } else {
        s.children[0].style.background = theme.colors.yellow;
        s.children[2].style.background = theme.colors.white;
      }
    });
  }

  useEffect(() => {
    if (!sliderInitialised) {
      setNumSelectedBits(Math.floor(limit));
      onSelectedDataChange({
        numSelectedBits: Math.floor(limit),
      });
    } else {
      onSelectedDataChange({
        numSelectedBits: numSelectedBits == 0 ? limit : numSelectedBits,
      });
    }
  }, [numSelectedBits]);

  useEffect(() => {
    updateStrokes();

    //Update BitPicker and Strokes with page resize
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [
    sliderTrack,
    rightSlider,
    leftSlider,
    selectedAreaBorderBottom,
    selectedAreaBorderTop,
    sliderSelectedRange,
    bitSelector,
    bitSelectorStart,
    sliderBorderAdjust,
    strokeColorOffsetLeft,
    strokeColorOffsetRight,
  ]);

  useEffect(() => {
    setNumSelectedBits(Number(bitSelectorValue));
  }, [bitSelectorValue]);

  function updateStrokes() {
    const strokesArray: JSX.Element[] = [];

    // Initialise strokes array
    const viewportWidth = document.documentElement.clientWidth;
    const strokeWidth = 20;
    const numStrokes = Math.round(viewportWidth / strokeWidth);
    strokesArray.length = 0; // Probably not needed

    // Initialise values
    if (viewportWidth < 576) {
      setSliderBorderAdjust(5);
      setBitSelectorAdjust(80);
      setStrokeColorOffsetLeft(20);
      setStrokeColorOffsetRight(-5);
      setStrokeColorOffsetRightBitEntry(20);
    } else if (viewportWidth >= 576 && viewportWidth < 768) {
      setSliderBorderAdjust(-5);
      setBitSelectorAdjust(150);
      setStrokeColorOffsetLeft(20);
      setStrokeColorOffsetRight(-20);
      setStrokeColorOffsetRightBitEntry(20);
    } else if (viewportWidth >= 768 && viewportWidth <= 992) {
      setSliderBorderAdjust(40);
      setBitSelectorAdjust(220);
      setStrokeColorOffsetLeft(15);
      setStrokeColorOffsetRight(-10);
      setStrokeColorOffsetRightBitEntry(60);
    } else if (viewportWidth > 992 && viewportWidth < 1440) {
      setSliderBorderAdjust(45);
      setBitSelectorAdjust(250);
      setStrokeColorOffsetLeft(15);
      setStrokeColorOffsetRight(-15);
      setStrokeColorOffsetRightBitEntry(70);
    } else if (viewportWidth >= 1440 && viewportWidth < 1920) {
      setSliderBorderAdjust(35);
      setBitSelectorAdjust(250);
      setStrokeColorOffsetLeft(30);
      setStrokeColorOffsetRight(-20);
      setStrokeColorOffsetRightBitEntry(100);
    } else if (viewportWidth >= 1920) {
      setSliderBorderAdjust(130);
      setBitSelectorAdjust(250);
      setStrokeColorOffsetLeft(20);
      setStrokeColorOffsetRight(-10);
      setStrokeColorOffsetRightBitEntry(120);
    } else {
      setSliderBorderAdjust(25);
      setBitSelectorAdjust(400);
      setStrokeColorOffsetLeft(10);
      setStrokeColorOffsetRight(-50);
      setStrokeColorOffsetRightBitEntry(80);
    }

    let counter = 0;
    for (let i = 0; i < numStrokes; i++) {
      switch (counter) {
        case 0:
          strokesArray.push(generatePlayerStroke('waveform-sm', i));
          break;
        case 1:
          strokesArray.push(generatePlayerStroke('waveform-xs', i));
          break;
        case 2:
          strokesArray.push(generatePlayerStroke('waveform-md', i));
          break;
        case 3:
          strokesArray.push(generatePlayerStroke('waveform-lg', i));
          break;
        case 4:
          strokesArray.push(generatePlayerStroke('waveform-xl', i));
          break;
        default:
          break;
      }
      counter === 4 ? (counter = 0) : counter++;
    }

    setPlayerStrokes(strokesArray);

    setBitSelectorStart(0);
  }

  function updateSize() {
    if (
      sliderTrack &&
      rightSlider &&
      leftSlider &&
      selectedAreaBorderBottom &&
      selectedAreaBorderTop &&
      sliderSelectedRange
    ) {
      updateStrokes();

      // Set selected area after BitEntry input
      const bitWidth = sliderTrack.offsetWidth / Math.floor(limit);
      const leftThumbPos = (leftSlider as HTMLInputElement).valueAsNumber * bitWidth;
      const rightThumbPos = (rightSlider as HTMLInputElement).valueAsNumber * bitWidth;

      selectedAreaBorderTop.style.width =
        rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';
      selectedAreaBorderBottom.style.width =
        rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';

      updatePlayerStrokes();
      moveBitsInput(rightThumbPos - leftThumbPos);
    }
  }

  function moveBitsInput(selectedAreaWidth?: number) {
    if (bitSelector && sliderSelectedRange) {
      const selectedWidth = selectedAreaWidth
        ? selectedAreaWidth
        : sliderSelectedRange.offsetWidth;

      if (selectedWidth < bitSelector.offsetWidth) {
        bitSelector.style.left = bitSelectorAdjust + 30 + 'px';
      } else {
        bitSelector.style.left = '0px';
      }
    }
  }

  moveBitsInput();

  const handleBitsSelectorInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = Math.floor(Number(e.target.value.replaceAll(',', '')));

    if (val > Math.floor(Number(limit))) {
      /* if number larger than remaining bits */
      val = Math.floor(Number(limit));
    }

    /* if (val < 1) {  /* Needed? means cannot set 0 but causes a bug with manyal entry
      val = 1
    } */

    if (!Number.isNaN(Number(val))) {
      setNumSelectedBits(Math.abs(Math.floor(Number(val))));
    } else {
      setNumSelectedBits(0);
    }

    if (
      sliderTrack &&
      rightSlider &&
      leftSlider &&
      selectedAreaBorderBottom &&
      selectedAreaBorderTop &&
      sliderSelectedRange
    ) {
      setLeftSliderValue((Math.floor(limit) - val) / 2);
      setRightSliderValue(Math.floor(limit) - (Math.floor(limit) - val) / 2);

      // Set selected area after BitEntry input
      const bitWidth = sliderTrack.offsetWidth / Math.floor(limit);
      let leftThumbPos = 0;
      let rightThumbPos = 0;

      setTimeout(() => {
        leftThumbPos =
          (leftSlider as HTMLInputElement).valueAsNumber * bitWidth;
        rightThumbPos =
          (rightSlider as HTMLInputElement).valueAsNumber * bitWidth;

        // Resize top and bottom borders of selected area
        selectedAreaBorderTop.style.width =
          rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';
        selectedAreaBorderBottom.style.width =
          rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';

        updatePlayerStrokes(
          leftThumbPos - 15,
          rightThumbPos + strokeColorOffsetRightBitEntry,
        );
        moveBitsInput(rightThumbPos - leftThumbPos);
      }, 20);
    }
  };

  const handleMultiRangeSliderChange = ({
                                          min,
                                          max,
                                        }: {
    min: number;
    max: number;
  }) => {
    if (sliderInitialised) {
      const selected = Math.floor(max - min);
      if (selected < 0) {
        setNumSelectedBits(1);
      } else {
        setNumSelectedBits(selected);
        setLeftSliderValue(min);
        setRightSliderValue(max);
      }
    } else {
      setTimeout(() => {
        setNumSelectedBits(Math.floor(limit));
        setSliderInitialised(true);
      }, 500);
    }

    if (
      sliderTrack &&
      selectedAreaBorderBottom &&
      selectedAreaBorderTop &&
      sliderSelectedRange &&
      leftSlider &&
      rightSlider
    ) {
      const borderWidth =
        sliderSelectedRange.offsetWidth + sliderBorderAdjust + 'px';
      selectedAreaBorderTop.style.width = borderWidth;
      selectedAreaBorderBottom.style.width = borderWidth;
    }

    updatePlayerStrokes();
    moveBitsInput();
  };

  const handlePredeterminedAmountButton = (amount: number) => {
    const bitPrice = songData.bitPrice;
    const numBits = Math.ceil(amount / bitPrice);

    if (
      sliderTrack &&
      rightSlider &&
      leftSlider &&
      selectedAreaBorderBottom &&
      selectedAreaBorderTop &&
      sliderSelectedRange
    ) {
      setLeftSliderValue((Math.floor(limit) - numBits) / 2);
      setRightSliderValue(
        Math.floor(limit) - (Math.floor(limit) - numBits) / 2,
      );

      // Set selected area after BitEntry input
      const bitWidth = sliderTrack.offsetWidth / Math.floor(limit);
      let leftThumbPos = 0;
      let rightThumbPos = 0;

      setTimeout(() => {
        leftThumbPos =
          (leftSlider as HTMLInputElement).valueAsNumber * bitWidth;
        rightThumbPos =
          (rightSlider as HTMLInputElement).valueAsNumber * bitWidth;

        // Resize top and bottom borders of selected area
        selectedAreaBorderTop.style.width =
          rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';
        selectedAreaBorderBottom.style.width =
          rightThumbPos - leftThumbPos + sliderBorderAdjust + 'px';

        updatePlayerStrokes(
          leftThumbPos - 15,
          rightThumbPos + strokeColorOffsetRightBitEntry,
        );
        moveBitsInput(rightThumbPos - leftThumbPos);
      }, 20);
    }

    setNumSelectedBits(numBits);
    purchaseModal();
  };

  return (
    <PlayerContainer id="container">
      <MultiRangeSlider
        min={0}
        max={Math.floor(limit)}
        leftSliderVal={leftSliderValue}
        rightSliderVal={rightSliderValue}
        onChange={handleMultiRangeSliderChange}
      />
      <StrokesContainer
        highlight={instructionalStage === InstractionalStages.bitsPicker}
        id="strokes">
        <StrokeSpacer ref={strokeContainerRef}>{playerStrokes}</StrokeSpacer>
        <SlidersContainer>
          <BitsSelectorContainer id="bit-selector">
            <BitSelector>
              <BitsSelectorInput
                className="bit-selector-input"
                width={115}
                height={29}
                inputName={'number-of-bits'}
                testId={'number-of-bits-value'}
                value={
                  sliderInitialised
                    ? numSelectedBits.toLocaleString()
                    : Math.floor(limit)
                }
                autoComplete={'off'}
                onKeyDown={(e) => {
                  e.target;
                }}
                type={'text'}
                placeholder={''}
                onFocus={(e) => {
                  e.target.value = '';
                }}
                inputMode={'numeric'}
                onInput={handleBitsSelectorInput}
                onChange={()=>{}}
              />
              <TextContent
                text={`bit${numSelectedBits > 1 ? 's' : ''}`}
                fontColor={theme.colors.yellow}
                fontWeight="bold"
              />
              <QuestionMarkButton>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          `Number of Bits you wish to ${songData.charity ? 'donate' : 'purchase'}.  Minimum ${songData.charity ? 'donation' : 'purchase'} limit may apply.`
                        }
                        fontSize={'fz14'}
                        lineHeight={'16px'}
                      />
                    </div>
                  }
                  width={210}
                  marginTop={-4}
                  marginLeft={
                    numSelectedBits > 1
                      ? viewport.width < 576
                        ? 9
                        : 9
                      : viewport.width < 576
                        ? 9
                        : 19
                  }
                  gtag_event="buy_help_text_bit_selector"
                />
              </QuestionMarkButton>
            </BitSelector>
          </BitsSelectorContainer>
        </SlidersContainer>
      </StrokesContainer>

      <Spacer height={20}/>

      {viewport.width < xs && (limit > Math.ceil(20 / songData.bitPrice) ||
        limit > Math.ceil(50 / songData.bitPrice) ||
        limit > Math.ceil(100 / songData.bitPrice)) && (
        <>
          <PresetText
            text="select above or use the quick selection below"
            fontSize="fz16"
            fontWeight="light"
            fontColor={theme.colors.gray}
          />

          <Spacer height={20}/>

          <PreDeterminedButtonContainer>
            {limit > Math.ceil(20 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-20"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$20"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(20)}
              />
            )}
            {limit > Math.ceil(50 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-50"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$50"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(50)}
              />
            )}
            {limit > Math.ceil(100 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-100"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$100"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(100)}
              />
            )}
          </PreDeterminedButtonContainer>
        </>
      )}

      {viewport.width < xs && (limit > Math.ceil(20 / songData.bitPrice) ||
        limit > Math.ceil(50 / songData.bitPrice) ||
        limit > Math.ceil(100 / songData.bitPrice)) && <Spacer height={10}/>}

      <SelectedInfo data-testid="selected-info">
        <TextContent
          dataTestId="bits-count"
          text={`${numSelectedBits.toLocaleString()} bit${
            numSelectedBits > 1 ? 's' : `${'  '}`
          } (${Math.round(numSelectedBits / 1000)}s) selected`}
          fontColor={theme.colors.yellow}
          fontSize={viewport.width < 576 ? 'fz20' : 'fz24'}
          fontWeight="bold"
        />
        <TextContent
          text="|"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
          fontWeight="bold"
          mobileHide
        />

        <SelectedInfoRow>
          <TextContent
            dataTestId="price-display"
            text={`${(numSelectedBits * songData.bitPrice).toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
              },
            )}`}
            fontColor={theme.colors.yellow}
            fontSize={viewport.width < 576 ? 'fz20' : 'fz24'}
            fontWeight="bold"
          />
        </SelectedInfoRow>
      </SelectedInfo>

      {(limit > Math.ceil(20 / songData.bitPrice) ||
        limit > Math.ceil(50 / songData.bitPrice) ||
        limit > Math.ceil(100 / songData.bitPrice)) && viewport.width > xs && (
        <>
          <Spacer height={20}/>

          <PresetText
            text={`drag the bars to select or type in the amount of bits to ${songData.charity ? 'donate' : 'purchase'} above or use the quick selection below`}
            fontSize="fz16"
            fontWeight="bold"
          />
          <Spacer height={30}/>
          <PreDeterminedButtonContainer>
            {limit > Math.ceil(20 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-20"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$20"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(20)}
              />
            )}
            {limit > Math.ceil(50 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-50"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$50"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(50)}
              />
            )}
            {limit > Math.ceil(100 / songData.bitPrice) && (
              <PresetButton
                className="song-profile-buy-100"
                height={45}
                borderRadius={50}
                borderColor={theme.colors.yellow}
                labelColor={theme.colors.white}
                label={
                  <TextContent
                    text="$100"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => handlePredeterminedAmountButton(100)}
              />
            )}
          </PreDeterminedButtonContainer>
        </>
      )}
      <Spacer height={30}/>
      <BuyButton
        className="song-profile-buy-button"
        height={45}
        borderRadius={50}
        bgColor={theme.colors.yellow}
        labelColor={theme.colors.black}
        label={
          <TextContent
            text={charity ? "donate" : "buy"}
            fontSize="fz16"
            fontWeight="bold"
            fontColor={theme.colors.black}
          />
        }
        onClick={purchaseModal}
      />
      <CustomSpacer/>

      <SocialShareSection show={viewport.width < xs} songData={songData}/>

      <Spacer height={20}/>
    </PlayerContainer>
  );
};

export default Player;
