import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import { useLocation } from 'react-router-dom';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { xs } from 'utils/constants';
import AccountBanner from '../assets/create-account.jpg';
import EnokiSSO from '../components/sso/EnokiSSO';
import {
  Form,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography as HeaderTitle,
  StrapLineTypographyContainer as HeaderTitleContainer,
  TextContent,
  TermsLink,
  TermsContainer,
  LoginContainer,
  LoginLink,
  Wrapper,
  HeaderTitleExtraContainer,
} from './styled/CreateAccount.styled';


const CreateAccount = () => {
  const viewport = useViewport();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    setArtistId('');
    setWaitlistToken('');
    setWaitlistEmail('');
  }, [location]);

  const [artistId, setArtistId] = useState<string>('');
  const [waitlistToken, setWaitlistToken] = useState<string>('');
  const [waitlistEmail, setWaitlistEmail] = useState<string>('');

  useEffect(() => {
    if (searchParams.get('artist')) {
      const artistId = searchParams.get('artist');
      if (artistId) {
        setArtistId(artistId);
      }
    }
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (token) {
      setWaitlistToken(token);
    }
    if (email) {
      setWaitlistEmail(email);
    }
  }, [waitlistToken, waitlistEmail, artistId]);


  return (
    <PageContainer pageTitle={'Create Account | SongBits'}>
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadContainer>
          <HeadingSection>
            <HeaderTitleContainer>
              <HeaderTitle
                text={viewport.width < xs ? 'Let’s get you ' : 'let’s get you '}
                fontSize="fz100"
                fontWeight="bold"
              />
              <HeaderTitleExtraContainer>
                <HeaderTitle
                  text="backstage"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? ' ' : '  '}
                <HeaderTitle
                  text="."
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
                {viewport.width < xs ? ' ' : '  '}
                <HeaderTitle text="." fontSize="fz100" fontWeight="bold" />
              </HeaderTitleExtraContainer>
            </HeaderTitleContainer>
          </HeadingSection>
        </HeadContainer>

        <Form>
          <TextContent
            text="Create Account"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '23px' }}>
            <EnokiSSO
              dataTestId="enoki-sso"
              dataArtistId={artistId}
              dataWaitlistToken={waitlistToken}
              dataWaitlistEmail={waitlistEmail}
              createAccount={true}
              showButtons={true}
              showEmail={false}
            />
          </div>

          <TermsContainer>
            By creating an account, you agree to be bound by our{' '}
            <TermsLink className={'terms-link'} to="/legal/terms-conditions" target="_blank">
              terms and conditions.
            </TermsLink>
          </TermsContainer>

          <LoginContainer>
            Already got a SongBits account?{'  '}
            <LoginLink className={'login-link'} to="/login">
              Login now
            </LoginLink>
          </LoginContainer>
        </Form>
      </Wrapper>
    </PageContainer>
  );
};

export default WithRouteAnimation(CreateAccount);
