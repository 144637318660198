function lastSunday(month: number, year: number) {
  const d = new Date();
  const lastDayOfMonth = new Date(
    Date.UTC(year || d.getFullYear(), month + 1, 0),
  );
  const day = lastDayOfMonth.getDay();
  return new Date(
    Date.UTC(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate() - day,
    ),
  );
}

export function isBST(date: any) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}

export function checkLive(date_string: string) {
  const str_date = date_string;

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));

  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second));

  if (isBST(date_)) {
    hour -= 1;
  }

  const date = new Date(
    Date.UTC(year, month, day, hour, minute, second),
  ).getTime();

  const now = Date.now();

  return {
    date_epoch: date,
    now_epoch: now,
  };
}
