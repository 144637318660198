import React, { FC } from 'react';
import styled from 'styled-components';

import { useViewport } from 'use-viewport';

import Typography from 'common/components/Typography/Typography';

interface ShareModalLayoutProps {
  onClose: () => void;
  contentClassname?: string;
  children?: React.ReactNode;
}

const ShareModalLayout: FC<ShareModalLayoutProps> = ({
  onClose,
  contentClassname,
  children,
}) => {
  const viewport = useViewport();

  return (
    <Wrapper>
      <Header>
        <CloseButton onClick={onClose}>X</CloseButton>
        <BoldText
          text="share"
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
      </Header>

      <Content className={contentClassname}>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Header = styled.div`
  margin-bottom: 30px;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    letter-spacing: -3px;
  }
`;

const Content = styled.div`
  width: 100%;
`;

export default ShareModalLayout;
