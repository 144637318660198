import React, { useEffect, useState, useRef } from 'react';
import { useViewport } from 'use-viewport';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import PageLayout from 'common/components/PageLayout/PageLayout';
import Spacer from 'common/components/Spacer/Spacer';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import Typography from 'common/components/Typography/Typography';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';

import { Details, YellowLine } from './styled/Transactions.styled';

import restService from 'services/rest.service';
import './styles/Royalties.css';
import './styles/RoyaltiesChart.css';
import { md } from 'utils/constants';

import theme from 'theme/theme';
import OpenYellow from 'common/icons/OpenYellow.icon';
import { IRoyaltyHistoryData } from 'typings/account';
import styled from 'styled-components';
import Button from 'common/components/Button/Button';

import { useStoreActions } from 'store/store';

import { roundNumberDown } from 'utils/functions';

export interface TransactionType {
  transactionid: string;
  date: string;
  timestamp: string;
  detail: string;
  amount: string;
  currency_amount: string;
  fees_amount: string;
  total_amount: string;
  transactiontype: string;
  sourcetype: string;
  status: string;
  data: {
    bits: string;
  };
}

type DropdownItem = {
  value: string | number;
  label: string;
};

const Transaction_Filters = [
  {
    label: 'All Periods',
    value: '',
  },
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2023',
    value: '2023',
  },
];

const quickLinks = [
  {
    title: 'My Wallet',
    link: '/account/wallet',
  },
  {
    title: 'My Collection',
    link: '/library/my-collection',
  },
  {
    title: 'Account Settings',
    link: '/account/settings',
  },
  {
    title: 'Royalty Overview',
    link: '/account/royalty',
  },
  {
    title: 'Song Royalty Overview',
    link: '/account/royalty/songs',
  },
];

const RoyaltyHistory = () => {
  const viewport = useViewport();

  const [loading, setLoading] = useState<boolean>(true);

  const [currentFilter, setCurrentFilter] = useState<DropdownItem>(
    Transaction_Filters[1],
  );
  const [transactionData, setTransactionData] =
    useState<IRoyaltyHistoryData[]>();
  const [chartAmountData, setChartAmountData] = useState<(number | null)[]>([]);

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  const chartRef = useRef<any>(null);
  const detailsRef = useRef<HTMLDivElement>(null);

  const categories = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const chartOptions: ApexOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    markers: {
      discrete: chartAmountData
        .filter((t) => t !== null)
        .map((_, index, arr) => ({
          seriesIndex: 0,
          dataPointIndex: index,
          fillColor:
            index === arr.length - 1 ? theme.colors.white : theme.colors.yellow,
          strokeColor:
            index === arr.length - 1 ? theme.colors.white : theme.colors.yellow,
          size: 7,
        })),
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      colors: [theme.colors.yellow],
      width: 4,
      dashArray: 10,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        offsetY: 8,
        style: {
          fontFamily: 'HKGrotesk-Black',
          fontSize: '14px',
          colors: theme.colors.white,
          fontWeight: 900,
        },
      },
      axisTicks: {
        color: theme.colors.yellow,
        offsetY: 10,
        height: 13,
      },
      axisBorder: {
        show: false,
        color: theme.colors.yellow,
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        show: false,
      },
      axisBorder: {
        color: theme.colors.yellow,
        width: 1,
        show: false,
      },
    },
  };

  const chartData = {
    options: chartOptions,
    series: [
      {
        name: 'series-1',
        data: chartAmountData,
      },
    ],
  };

  function padEnd(array: (number | null)[], minLength: number) {
    return Object.assign(new Array(minLength).fill(null), array);
  }

  useEffect(() => {
    if (!chartRef) return;

    const xAxisChartLines = document.querySelectorAll('.apexcharts-xaxis-tick');

    xAxisChartLines.forEach((lineNode) => {
      lineNode.setAttribute('stroke-width', '3');
    });
  }, [chartAmountData, chartRef]);

  useEffect(() => {
    restService
      .getRoyaltyHistory(
        'json',
        currentFilter.value ? currentFilter.value.toString() : '',
      )
      .then((res) => {
        const data = res;
        setTransactionData(data);

        const chartData = res.map((t: any) => {
          //console.log(t.period.substring(0,3))
          if (t.amount.length === 0) {
            return 0;
          } else {
            return Number(t.amount.replace('$', ''));
          }
        });

        /* Array must be padded to 12 elements to show all
         * months on the chart. Padded values must be null
         * to prevent anything displaying on the chart */

        // padd list as required to show on correct position on the chart

        const padBy = categories.indexOf(res[0].period.substring(0, 3));

        if (padBy > 0) {
          let i = padBy;
          while (i > 0) {
            chartData.unshift(null);
            i -= 1;
          }
        }

        setChartAmountData(padEnd(chartData, 12));
      })
      .catch((error) => {
        error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentFilter]);

  useEffect(() => {
    setBreadcrumbs([
      { value: 'Home', label: 'Home' },
      { value: 'account', label: 'My Account' },
      { value: 'royalty' || '', label: 'Royalty' },
      { value: 'history' || '', label: 'History' },
    ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, []);

  function exportData() {
    restService
      .getRoyaltyHistory(
        'csv',
        currentFilter.value ? currentFilter.value.toString() : '',
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'royalty-history-export.csv');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        error;
      });
  }

  const firstColumn = (
    <>
      {loading ? (
        <div style={{ width: '100%' }}>
          <span
            style={{ borderRadius: '10px' }}
            className={
              'placeholder-wave placeholder fs-36 lh-48 bg-secondary col-7'
            }>
            &nbsp;
          </span>

          <table className="Transations">
            <thead>
              <tr>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-10'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
                    }>
                    &nbsp;
                  </span>
                </th>
                <th>
                  <span
                    className={
                      'placeholder-wave placeholder fs-18 lh-24 bg-secondary col-8'
                    }>
                    &nbsp;
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td
                    colSpan={5}
                    style={{ paddingTop: '20px', paddingBottom: 'unset' }}>
                    <span
                      className={
                        'placeholder-wave placeholder fs-18 lh-36 bg-secondary col-12'
                      }>
                      &nbsp;
                    </span>
                    <Spacer height={10} />
                    {index < 9 && (
                      <span
                        style={{ minHeight: '2px', maxHeight: '2px' }}
                        className={
                          'placeholder-wave placeholder fs-18 lh-36 bg-secondary col-12'
                        }>
                        &nbsp;
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Details ref={detailsRef}>
          <Dropdown
            data={Transaction_Filters}
            currentItem={currentFilter}
            onSelect={(item) => setCurrentFilter(item)}
          />
          <table className="RoyaltyHistory">
            <thead>
              <tr>
                <th>Period</th>
                <th>Streams</th>
                <th>Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactionData &&
                transactionData.map((t, index) => (
                  <React.Fragment key={index}>
                    <tr style={{ cursor: 'pointer' }} onClick={() => {}}>
                      <td>{t.period}</td>
                      <td>{parseInt(t.total_streams).toLocaleString()}</td>
                      <td>{t.status}</td>
                      <td>${roundNumberDown(t.amount)}</td>
                    </tr>
                    {index < transactionData.length - 1 ? (
                      <>
                        <tr>
                          <td colSpan={5} style={{ padding: '0px' }}>
                            <YellowLine />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          <Spacer height={40} />
          {transactionData && transactionData?.length <= 12 ? (
            <>
              <ChartContainer>
                <Chart
                  ref={chartRef}
                  options={chartData.options}
                  series={chartData.series}
                  // width={644}
                  type="line"
                />

                <XAxis />
                <YAxis />
              </ChartContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 10,
                }}>
                <SongLink
                  label={
                    <>
                      <OpenYellow width={20} height={20} />
                      <Typography
                        text={`Export song royalty overview`}
                        fontSize="fz18"
                        fontWeight="regular500"
                        lineHeight="24px"
                        letterSpacing="-0.03em"
                        fontColor={theme.colors.yellow}
                      />
                    </>
                  }
                  onClick={() => {
                    exportData();
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          <Spacer height={viewport.width >= md ? 80 : 10} />
        </Details>
      )}
    </>
  );

  return (
    <PageContainer pageTitle={'Royalty History | SongBits'}>
      <BreadcrumbsContainer>
        {viewport.width < 576 && <MobileBreadcrumbs />}
      </BreadcrumbsContainer>
      <PageLayout
        loading={loading}
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
        }
        is2ColumnLayout={true}
        title="Royalty History"
        sections={[
          {
            content: firstColumn,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                  marginTop: viewport.width < md ? '0px' : '15px',
                }}>
                {loading && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} isLogout={true} />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  position: relative;
`;

const XAxis = styled.div`
  width: 100%;
  height: 2px;
  background: black;
  background: linear-gradient(90deg, black 0%, #ffd600 100%);

  position: absolute;
  bottom: 50px;
`;

const YAxis = styled.div`
  width: 2px;
  height: 100%;
  background: black;
  background: linear-gradient(180deg, black 0%, #ffd600 100%);

  position: absolute;
  bottom: 50px;
  right: 0;
`;

export const SongLink = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
`;

export default RoyaltyHistory;
