import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router';
import { getUserIdFromJWT } from 'utils/functions';

import { notify } from 'common/components/Toaster/Toaster';
import RestrictedCountry from 'modules/payments/PaymentModals/RestrictedCountry';
import BuyModalPaymentSelection from './components/Player/components/BuyModals';
import Spacer from 'common/components/Spacer/Spacer';
//import ErrorPage from 'modules/prereg/pages/ErrorPage';
import PageContainer from 'common/layout/PageContainer';
import Player from 'modules/library/pages/components/Player/Player';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import SongInfo from './components/SongInfo/SongInfo';

import { getSoldPercent } from 'utils/calculation';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';

import {
  InfoSpacer,
  PlayerContainer,
  BreadcrumbsContainer,
  ErrorTextBold,
  FCABannerContainer,
} from './styled/SongProfile.styled';

import './styles/BuyModal.scss';

import {
  GET_SONG_BY_SLUG,
  GET_USERS_COUNTRY,
} from '../graphql/Queries.graphql';
import { VIEW_TRACKING } from '../graphql/Mutations.graphql';

import {
  SongDataType,
  artistPlatformType,
  VIEW_TRACKING_MUTATION,
  VIEW_TRACKING_VARIABLES,
} from '../types'; // SongsTag

import Data from '../assets/MOCK_API_DATA';
import { useStoreActions, useStoreState } from 'store/store';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';
import { resetPersist } from 'store-persist/reducers/modalSlice';
import { checkLive } from 'utils/live-check';

import QueueFull from 'modules/payments/PaymentModals/QueueFull';

const SongProfile = () => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const userId: any = getUserIdFromJWT();
  const { song } = useParams();

  const dispatch = useAppDispatch();
  const isArtist = useStoreState(
    (state) => state.authentication.auth.tokenData?.is_artist || false,
  );

  const setIsHeaderTransparent = useStoreActions(
    (state) => state.style.setHeaderTransparent,
  );

  const setShowFCABanner = useStoreActions(
    (state) => state.style.setShowFCABanner,
  );

  const visitorLocation = useStoreState(
    (state) => state.authentication.location,
  );
  const showFCABanner = useStoreState((state) => state.style.showFCABanner);
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );

  const {
    isReloaded,
    dataState,
    showState,
    modalType,
    location: modalLocation,
  } = useAppSelector((state) => state.modalSlice);

  const [songData, setSongData] = useState<SongDataType>();
  const [secondsSelected, setSecondsSelected] = useState<number>(220);
  const [selectedBits, setSelectedBits] = useState<number>(1);

  const [toastId, setToastId] = useState<string>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const [showBuyModal, setShowBuyModal] = useState<boolean>(false);
  const [showQueueFullModal, setShowQueueFullModal] = useState<boolean>(false);

  const [showRestrictedCountryModal, setShowRestrictedCountryModal] =
    useState<boolean>(false);
  //const [showInstructionalModal, setInstructionalModal] = useState<boolean>(false);

  const storedBits = useStoreState((state) => state.purchase.numberOfBits);

  const clearPurchaseStore = useStoreActions(
    (state) => state.purchase.resetPurchaseData,
  );
  const setCommission = useStoreActions(
    (actions) => actions.purchase.setCommission,
  );
  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQueryParams();

  const { data: userCountry } = useQuery(GET_USERS_COUNTRY, {
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
    skip: !userId,
  });

  const { data: songProfile, refetch } = useQuery(GET_SONG_BY_SLUG, {
    variables: { slug: song, userid: userId },
    notifyOnNetworkStatusChange: true,
  });

  const [createViewTracking] = useMutation<
    VIEW_TRACKING_MUTATION,
    VIEW_TRACKING_VARIABLES
  >(VIEW_TRACKING);

  toastId;

  useEffect(() => {
    setShowFCABanner(true);
    return () => {
      setShowFCABanner(false);
    };
  }, []);

  useEffect(() => {
    setIsHeaderTransparent(true);

    return () => {
      setIsHeaderTransparent(false);
    };
  }, []);

  useEffect(() => {
    if (songData)
      setBreadcrumbs([
        { value: 'Home', label: 'Home' },
        { value: songData.artistSlug, label: songData.artistName },
        { value: song || '', label: songData.title },
        { value: 'bits', label: songData.charity ? 'Donate' : 'Buy' },
      ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, [songData]);

  useEffect(() => {
    if (
      modalLocation === location.pathname &&
      modalType === 'ConfirmPurchase'
    ) {
      setShowBuyModal(showState);
    }

    if (
      [
        'ConfirmPurchase',
        'AddFundsOptions',
        'AddCryptoFunds',
        'CryptoAuto',
        'ConnectWallet',
        'CardAuto',
      ].includes(modalType) &&
      dataState &&
      isReloaded
    ) {
      setShowBuyModal(true);
    }
  }, []);

  useEffect(() => {
    if (songProfile && songProfile.songBySlug) {
      const data = songProfile.songBySlug;

      let nft_id = ''; // Fix for song not loading
      try {
        nft_id = data.nftInstancesBySongId.nodes[0].id;
      } catch {}

      let songLiked = null;

      try {
        songLiked = data.songlikesByLikedSong.edges[0].node.id;
      } catch {}

      const is_live = checkLive(data.releaseAt);

      let numberOfStreams = 0;
      if (data.numberOfStreams && numberOfStreams > 0) {
        numberOfStreams = data.numberOfStreams;
      }

      const totalBits = data.audioLengthMs;
      const bitCount = JSON.parse(data.bitCount);
      let soldBits = bitCount.sold_bits;
      let remainingBits = bitCount.remaining_bits;
      let soldBitsInverted = bitCount.sold_bits_inverted;
      let actualBitPrice = data.actualBitPrice;

      if (data.slug === 'drop') {
        soldBits = totalBits;
        remainingBits = 0;
        soldBitsInverted = 0;
        actualBitPrice = 0.1;
      }

      const soldPercent = getSoldPercent(
        totalBits,
        data.editions,
        soldBitsInverted,
      );
      let charity = false;
      if (data.artistProfileByArtistId.slug === 'bigissue') {
        charity = true;
      }

      const newSongData = {
        artistName: data.artistName,
        artistSlug: data.artistProfileByArtistId.slug,
        artwork: data.fileByCoverId.url,
        audioLengthMs: data.audioLengthMs,
        bitPrice: actualBitPrice,
        editions: data.editionsCount,
        id: data.id,
        owners: data.ownersCount,
        remainingBits: remainingBits,
        soldBits: soldBits,
        soldBitsInverted: soldBitsInverted,
        allowedBits: remainingBits,
        songId: data.id,
        songUrl: data.fileByAudioId.url,
        tags: data.songsTagsBySongId.nodes,
        title: data.title,
        releaseYear: data.releaseYear,
        userId: data.artistProfileByArtistId.userId,
        nft: nft_id,
        commission: {
          songbits_sale: JSON.parse(data.commission).songbits_sale,
          songbits_asset_royalty: JSON.parse(data.commission)
            .songbits_asset_royalty,
        },
        musicLinks: {
          spotify: data.spotify,
          applemusic: data.apple,
          youtube: data.youtube,
          trackd: data.trackd,
        },
        songLiked: songLiked,
        live: is_live.now_epoch > is_live.date_epoch,
        minimumBitPurchase: data.minimumBitPurchase,
        maximumBitPurchase: data.maximumBitPurchase,
        combinedStreamCount: data.artistProfileByArtistId.combinedStreamCount,
        numberOfStreams: numberOfStreams,
        explicitContent: data.explicitContent,
        soldPercent: soldPercent,
        status: data.status,
        songType: data.songType,
        artistPlatform: data.artistProfileByArtistId
          .connectPlatform as artistPlatformType,
        charity: charity,
        royaltyDonation: false,
      };

      setCommission(newSongData.commission);

      if (JSON.stringify(newSongData) !== JSON.stringify(songData)) {
        setSongData(newSongData);
        setCommission(newSongData.commission);
      }

      (window as any).gtag('event', 'view_item', {
        items: [
          {
            item_id: data.id,
            item_name: `${data.title}`,
            item_brand: `${data.artistName}`,
            price: actualBitPrice,
          },
        ],
      });

      createViewTracking({
        variables: {
          type: 'SONG',
          viewedItem: data.id,
          userId: userId,
        },
      });

      if (newSongData.soldBitsInverted === 0) {
        navigate(location.pathname.replace('/bits', ''));
      }

      if (newSongData.remainingBits === 0) {
        setShowQueueFullModal(true);
      } else {
        setShowQueueFullModal(false);
      }

      setRefresh(false);
    }
  }, [songProfile]);

  useEffect(() => {
    const state = query.get('state');
    if (state === 'restore' && storedBits) {
      setSelectedBits(+storedBits);
      setShowBuyModal(true);
    } else {
      clearPurchaseStore();
    }
  }, []);

  useEffect(() => {
    if (showBuyModal) {
      (window as any).gtag('event', 'purchase_open', {});
    }
  }, [showBuyModal]);

  useEffect(() => {
    if (isArtist) {
      navigate(location.pathname.replace('/bits', ''));
    }
  }, [isArtist, location.pathname]);

  useEffect(() => {
    if (songData) {
      if (
        songData.soldBitsInverted === 0 ||
        songData.songType === 'SALE_WITHDRAWN' ||
        !songData.live
      ) {
        navigate(location.pathname.replace('/bits', ''));
      }
    }
  }, [songData, location.pathname]);

  useEffect(() => {
    if (isArtist) {
      navigate(location.pathname.replace('/bits', ''));
    }
  }, [isArtist, location.pathname]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(location.pathname.replace('/bits', ''));
    }
  }, [isAuthenticated, location.pathname]);

  return (
    <>
      {songData && userCountry ? (
        <FCABannerContainer
          fcaBanner={
            showFCABanner &&
            (visitorLocation === 'GB' ||
              visitorLocation === 'NI' ||
              visitorLocation === 'UK')
          }>
          <PageContainer
            pageTitle={
              'Buy ' +
              songData.title +
              ' : ' +
              songData.artistName +
              ' | SongBits'
            }>
            <BreadcrumbsContainer>
              <MobileBreadcrumbs />
            </BreadcrumbsContainer>
            <InfoSpacer height={viewport.width < 576 ? 0 : 40} />

            <SongInfo songData={songData} />

            <PlayerContainer>
              {refresh ? (
                <Player
                  key={'player 1'}
                  secondsSelected={secondsSelected}
                  costPerBitDAI={Data.DAI}
                  purchaseModal={() => {
                    if (selectedBits < songData.minimumBitPurchase) {
                      const toastId = notify({
                        autoClose: false,
                        customComponent: (
                          <ToasterModal
                            title="Whoops!.."
                            content={
                              <div>
                                The minimum purchase amount is{' '}
                                <ErrorTextBold>
                                  $
                                  {songData.minimumBitPurchase *
                                    songData.bitPrice}{' '}
                                  ({songData.minimumBitPurchase} bits)
                                </ErrorTextBold>
                              </div>
                            }
                          />
                        ),
                      });
                      setToastId(toastId as string);
                    } else if (
                      userCountry.userById.countryByCountry.circleProhibited
                    ) {
                      setShowRestrictedCountryModal(true);
                    } else if (
                      selectedBits > 0 &&
                      selectedBits * songData.bitPrice > 0
                    ) {
                      setShowBuyModal(true);
                    }
                  }}
                  setSecondsSelected={setSecondsSelected}
                  songData={songData}
                  limit={
                    songData.remainingBits > songData.maximumBitPurchase
                      ? songData.maximumBitPurchase
                      : songData.remainingBits
                  }
                  onSelectedDataChange={({
                    numSelectedBits,
                  }: {
                    numSelectedBits: number;
                  }) => {
                    // Set num selected bits
                    setSelectedBits(numSelectedBits);
                  }}
                  charity={songData.charity}
                />
              ) : (
                <Player
                  key={'player 2'}
                  secondsSelected={secondsSelected}
                  costPerBitDAI={Data.DAI}
                  purchaseModal={() => {
                    if (selectedBits < songData.minimumBitPurchase) {
                      const toastId = notify({
                        autoClose: false,
                        customComponent: (
                          <ToasterModal
                            title="Whoops!.."
                            content={
                              <div>
                                The minimum{' '}
                                {songData.charity ? 'donation' : 'purchase'}{' '}
                                amount is{' '}
                                <ErrorTextBold>
                                  $
                                  {songData.minimumBitPurchase *
                                    songData.bitPrice}{' '}
                                  ({songData.minimumBitPurchase} bits)
                                </ErrorTextBold>
                              </div>
                            }
                          />
                        ),
                      });
                      setToastId(toastId as string);
                    } else if (
                      userCountry.userById.countryByCountry.circleProhibited
                    ) {
                      setShowRestrictedCountryModal(true);
                    } else if (
                      selectedBits > 0 &&
                      selectedBits * songData.bitPrice > 0
                    ) {
                      setShowBuyModal(true);
                    }
                  }}
                  setSecondsSelected={setSecondsSelected}
                  songData={songData}
                  limit={
                    songData.remainingBits > songData.maximumBitPurchase
                      ? songData.maximumBitPurchase
                      : songData.remainingBits
                  }
                  onSelectedDataChange={({
                    numSelectedBits,
                  }: {
                    numSelectedBits: number;
                  }) => {
                    // Set num selected bits
                    setSelectedBits(numSelectedBits);
                  }}
                  charity={songData.charity}
                />
              )}
            </PlayerContainer>

            <Spacer height={15} />

            {/* Buy Modals */}
            {[
              'ConfirmPurchase',
              'AddFundsOptions',
              'AddCryptoFunds',
              'CryptoAuto',
              'ConnectWallet',
              'CardAuto',
            ].includes(modalType) &&
            dataState &&
            isReloaded ? (
              <BuyModalPaymentSelection
                showModals={showBuyModal}
                song={dataState.title}
                artist={dataState.artist}
                songId={dataState.songId}
                selectedBits={dataState.selectedBits}
                costOfSelected={dataState.costOfSelected}
                dai={dataState.dai}
                bitPrice={dataState.bitPrice}
                songPoster={songData.artwork}
                uri={dataState.songUrl}
                nft={dataState.nft}
                commission={dataState.commission}
                artistPlatform={dataState.artistPlatform}
                charity={dataState.charity}
                royaltyDonation={dataState.royaltyDonation}
                onClose={() => {
                  setShowBuyModal(false);
                  dispatch(resetPersist());
                  // window.location.reload();
                }}
                refetchSongProfile={() => {
                  refetch();
                  setRefresh(!refresh);
                }}
              />
            ) : (
              <BuyModalPaymentSelection
                showModals={showBuyModal}
                song={songData.title}
                songId={songData.id}
                artist={songData.artistName}
                selectedBits={
                  !dataState ? selectedBits : dataState.selectedBits
                }
                costOfSelected={
                  !dataState
                    ? String(songData.bitPrice * selectedBits)
                    : dataState.costOfSelected
                }
                dai={`${String(Data.DAI)}`}
                bitPrice={songData.bitPrice}
                songPoster={songData.artwork}
                uri={songData.songUrl}
                nft={songData.nft}
                commission={songData.commission}
                artistPlatform={songData.artistPlatform}
                charity={songData.charity}
                royaltyDonation={songData.royaltyDonation}
                onClose={() => {
                  setShowBuyModal(false);
                  dispatch(resetPersist());
                  // window.location.reload();
                }}
                refetchSongProfile={() => {
                  refetch();
                  setRefresh(!refresh);
                }}
              />
            )}

            <QueueFull
              isOpen={showQueueFullModal}
              artist={songData.artistName}
              song={songData.title}
              image={songData.artwork}
              onRefresh={() => {
                window.location.reload();
              }}
              onClose={() => {
                dispatch(resetPersist());
                navigate('/');
              }}
            />

            {
              <RestrictedCountry
                isOpen={showRestrictedCountryModal}
                onClose={() => {
                  setShowRestrictedCountryModal(false);
                }}
                country={
                  userCountry && userCountry.userById.countryByCountry?.name
                    ? userCountry.userById.countryByCountry.name
                    : ''
                }
              />
            }
          </PageContainer>
        </FCABannerContainer>
      ) : (
        <>{/*!songData && songProfile ? <ErrorPage /> : <></>*/}</>
      )}
    </>
  );
};

export default SongProfile;
