import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';
import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import Caption from 'common/components/Caption/Caption';
import IconCaption from 'common/components/IconCaption/IconCaption';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Visa from 'common/icons/Visa.icon';
import Amex from 'common/icons/Amex.icon';
import DinersClub from 'common/icons/DinersClub.icon';
import Discover from 'common/icons/Discover.icon';
import UnionPay from 'common/icons/UnionPay.icon';
import Clock from 'common/icons/Clock.icon';

import { MONTHS } from 'utils/constants';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import theme from 'theme/theme';
import { getUserIdFromJWT } from 'utils/functions';
import { GET_USERS_COUNTRY } from 'modules/library/graphql/Queries.graphql';
import SBModal from 'common/components/Modal/SBModal';
import { roundNumberDown } from 'utils/functions';

import { PaymentModalType } from 'modules/payments/types';
import restService from 'services/rest.service';
import Mastercard from 'common/icons/Mastercard.icon';

import AddCard from 'modules/payments/PaymentModals/AddCard';
import {
  BorderLineYellow,
  CardButtonContainer,
  EditCard,
  IconButton,
  NewCard,
  TransactionLink,
  PaddingContainer,
} from './styled/MyWallet.styled';

import Edit from 'common/icons/Edit.icon';
import Delete from 'common/icons/Delete.icon';
import RemoveCardModal from './components/Wallet/RemoveCardModal';
import RestrictedCountry from 'modules/payments/PaymentModals/RestrictedCountry';
import PageLayout from 'common/components/PageLayout/PageLayout';
import ImagePicker from '../../../common/components/ImagePicker/ImagePicker';

import OpenYellow from '../../../common/icons/OpenYellow.icon';
import { Button } from '../../library/pages/styled/SongProfilePublic.styled';

export interface CardDetails {
  cardid: string;
  nickname: string;
  last4: string;
  bin: string;
}

interface RemoveCardType {
  show: boolean;
  cardid: string;
}

const MyWallet = () => {
  const userId = getUserIdFromJWT();
  const viewport = useViewport();

  const [data, setData] = useState<any>({});

  const [showRestrictedCountryModal, setShowRestrictedCountryModal] =
    useState<boolean>(false);
  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);
  const [cardId, setCardId] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<string>('card');
  setPaymentMethod;

  const [avatar, setAvatar] = useState<string>('');

  const [isPaypal, setIsPaypal] = useState<boolean>(false);
  isPaypal;
  const [paypalEmail, setPaypalEmail] = useState<string>('');

  const { data: userCountry, loading: countryLoading } = useQuery(
    GET_USERS_COUNTRY,
    {
      variables: { id: userId },
      notifyOnNetworkStatusChange: false,
    },
  );

  const [showRemoveCardModal, setShowRemoveCardModal] =
    useState<RemoveCardType>({
      show: false,
      cardid: '',
    });

  const quickLinks = useMemo(
    () => [
      {
        title: 'My Collection',
        link: '/library/my-collection',
      },
      {
        title: 'Transaction History',
        link: '/account/transactions',
      },
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
      {
        title: 'My Royalties',
        link: '/account/royalty',
      },
    ],
    [],
  );

  function formatDate(date: string) {
    const d = new Date(date);
    return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear();
  }

  function refetch() {
    restService.myWallet().then((data) => {
      if (parseFloat(data.balance) <= 0) {
        data.balance = '0.00';
      } else data.balance = roundNumberDown(data.balance);
      if (
        parseFloat(
          data.balance_increase_month.replace('%', '').replace('+', ''),
        ) <= 0
      ) {
        data.balance_increase_month = '+ 0.00%';
      } else {
        data.balance_increase_month = data.balance_increase_month.replace(
          '+',
          '+ ',
        );
      }

      setData(data);
      if (data.card && data.card.cardid) {
        setCardId(data.card.cardid);
      }

      setAvatar(data.profile_image);
      if (data.paypal) {
        setPaypalEmail(data.paypal.email_address);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    refetch();
  }, []);

  const handleImage = (avatar: string) => {
    // update profile with image
    setAvatar(avatar);
  };

  const Card = ({ icon, text }: { icon: JSX.Element; text: string }) => {
    return (
      <EditCard>
        <IconCaption
          className="linked-card"
          icon={icon}
          text={text}
          fontSize="fz14"
          fontWeight="regular"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const SmallCaption = ({
    icon,
    text,
  }: {
    icon: JSX.Element;
    text: string;
  }) => {
    return (
      <EditCard>
        <IconCaption
          icon={icon}
          text={text}
          fontSize="fz14"
          fontWeight="bold"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const EditButtons = ({ card }: { card?: string; email?: string }) => {
    //setCardId(card);
    return (
      <CardButtonContainer>
        <IconButton
          label={<Delete />}
          className={
            !paypalEmail ? 'remove-card-button' : 'remove-paypal-email-button'
          }
          onClick={() => {
            if (card) setShowRemoveCardModal({ show: true, cardid: card });
            //if (paypalEmail) setPaypalEmail('');
            if (paypalEmail) setIsPaypal(true);
          }}
        />
        <IconButton
          label={<Edit width={19} height={19} />}
          className="edit-card-button"
          onClick={() => {
            if (card) setShowAddCardModal(true);
            if (paypalEmail) setIsPaypal(true);
          }}
        />
      </CardButtonContainer>
    );
  };

  const secondColumn = (
    <>
      {loading || countryLoading ? (
        <>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={viewport.width >= 576 ? 80 : 10} />
        </>
      ) : (
        <>
          <PaddingContainer>
            <Typography
              fontSize={viewport.width >= 576 ? 'fz60' : 'fz38'}
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text={'$' + data.balance}
            />
            <Spacer height={6} />
            <Typography fontSize="fz24" fontWeight="light" text="available*" />
            <Spacer height={10} />
            <Typography
              fontSize="fz14"
              fontWeight="bold"
              text={data.balance_increase_month}
            />
            <Spacer height={10} />
            <TransactionLink to={`/account/transactions`}>
              <OpenYellow width={20} height={20} />
              <Typography
                text={'View transaction history'}
                fontSize="fz18"
                fontWeight="regular500"
                lineHeight="24px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
            </TransactionLink>
            <Spacer height={20} />
            {data.payouts ? (
              <>
                <Typography
                  fontSize="fz24"
                  fontWeight="light"
                  text="pending payout"
                />
                <Spacer height={12} />
                <Typography
                  fontSize="fz14"
                  fontWeight="bold"
                  text={
                    '$' +
                    data.payouts.amount +
                    '  (Requested ' +
                    formatDate(data.payouts.timestamp) +
                    ')'
                  }
                />
              </>
            ) : (
              <>
                <Button
                  onClick={() => {}}
                  disabled={
                    parseFloat(data.balance) >
                      parseFloat(
                        process.env.REACT_APP_PAYOUT_MINIMUM || '0.00',
                      ) && data.paypal
                      ? false
                      : true
                  }
                  opacity={
                    parseFloat(data.balance) >
                      parseFloat(
                        process.env.REACT_APP_PAYOUT_MINIMUM || '0.00',
                      ) && data.paypal
                      ? 1
                      : 0.5
                  }
                  backgroundColor={'#000000'}
                  textColor={'#FFFFFF'}
                  border={'#FFFFFF'}>
                  withdraw
                </Button>
              </>
            )}

            <Spacer height={19} />
          </PaddingContainer>
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={24} />
            <Typography
              fontSize="fz24"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text="Payment Options"
            />
            <Spacer height={30} />
            <Caption
              fontWeight="bold"
              text="Payment Method"
              tip="Add a payment method to use to purchase SongBits"
            />
            <Spacer height={26} />
            {paymentMethod === 'card' && (
              <>
                <SmallCaption
                  icon={<Clock />}
                  text="Instant | Visa & Mastercard Only"
                />
                {data.card && data.card.cardid ? (
                  <>
                    <Spacer height={30} />
                    <EditCard>
                      {data.card.bin.startsWith('34') ||
                      data.card.bin.startsWith('37') ? (
                        <>
                          <Card
                            icon={<Amex height={35} width={35} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {data.card.bin.startsWith('4') ? (
                        <>
                          <Card
                            icon={<Visa height={35} width={35} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {data.card.bin.startsWith('5') ? (
                        <>
                          <Card
                            icon={<Mastercard height={35} width={45} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {data.card.bin.startsWith('36') ? (
                        <>
                          <Card
                            icon={<DinersClub height={35} width={45} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {data.card.bin.startsWith('6011') ? (
                        <>
                          <Card
                            icon={<Discover height={35} width={45} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {data.card.bin.startsWith('62') ? (
                        <>
                          <Card
                            icon={<UnionPay height={35} width={45} />}
                            text={data.card.pan}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <EditButtons card={data.card.cardid} />
                    </EditCard>
                  </>
                ) : (
                  <></>
                )}

                {!data.card && (
                  <>
                    <Spacer height={30} />
                    <NewCard
                      className="add-new-card-button"
                      label="Add New Card"
                      onClick={() => {
                        setShowAddCardModal(true);
                      }}
                    />
                  </>
                )}
              </>
            )}
            <Spacer height={25} />
          </PaddingContainer>
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={23} />
            <Typography
              fontSize="fz24"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text="Payout Options"
            />
            <Spacer height={25} />
            <Caption
              fontWeight="bold"
              text="Coming Soon"
              tip="Ability to withdraw your royalties is coming soon"
            />
            <Spacer height={24} />
          </PaddingContainer>
          <Spacer height={25} />
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={25} />
            <Typography
              fontSize="fz12"
              fontColor={theme.colors.white}
              lineHeight="1.5"
              text="*Subject to the delayed royalty reports having been received from the distributor"
            />
          </PaddingContainer>
        </>
      )}
    </>
  );

  return (
    <>
      <PageContainer pageTitle={'My Wallet | SongBits'}>
        <BreadcrumbsContainer>
          {viewport.width < 576 && <MobileBreadcrumbs />}
        </BreadcrumbsContainer>
        <PageLayout
          loading={loading || countryLoading}
          secondColumnWidth="340px"
          padding={
            viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
          }
          title="My Wallet"
          sections={[
            {
              content: (
                <>
                  {!(loading || countryLoading) ? (
                    <>
                      <ProfileImageContainer>
                        <ImagePicker
                          type="avatar"
                          image={avatar}
                          handleImage={handleImage}
                        />
                      </ProfileImageContainer>
                    </>
                  ) : (
                    <>
                      <ImagePickerPlaceholder></ImagePickerPlaceholder>
                    </>
                  )}
                </>
              ),
            },
            {
              content: secondColumn,
            },
            {
              content: (
                <div
                  style={{
                    display: 'flex',
                    gap: '25px',
                    flexDirection: 'column',
                    marginTop: '15px',
                  }}>
                  {loading ? (
                    <QuickLinksPlaceholder></QuickLinksPlaceholder>
                  ) : (
                    <>
                      {viewport.width <= 767 ? (
                        <></>
                      ) : (
                        <QuickLinks
                          addFundsTrigger={() => {}}
                          addFunds={false}
                          links={quickLinks}
                          isLogout
                        />
                      )}
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />

        <AddCard
          isOpen={showAddCardModal}
          type={PaymentModalType.Normal}
          fromMyWallet={true}
          cardId={cardId}
          onClose={() => {
            refetch();
            setShowAddCardModal(false);
          }}
          onCardAdded={() => {
            refetch();
            setShowAddCardModal(false);
          }}
        />

        <RestrictedCountry
          isOpen={showRestrictedCountryModal}
          onClose={() => {
            setShowRestrictedCountryModal(false);
          }}
          country={
            userCountry && userCountry.userById.countryByCountry.name
              ? userCountry.userById.countryByCountry.name
              : ''
          }
        />

        <SBModal
          className={'remove-card'}
          isOpen={showRemoveCardModal.show}
          width="434px"
          height="280px"
          top="30%"
          content={
            <RemoveCardModal
              cardId={showRemoveCardModal.cardid}
              onClose={() => {
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
              onDelete={() => {
                refetch();
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
            />
          }
        />
      </PageContainer>
    </>
  );
};

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export default MyWallet;
