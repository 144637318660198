import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';

import HamburgerIcon from 'common/icons/Hamburger.icon';
import LogoSmall from 'common/icons/LogoSmallBeta.icon';
import ProfileIcon from 'common/icons/Profile.icon';

import {
  AccountButtons,
  //BackButton,
  Container,
  InfoSection,
  LogoButton,
  LogoWrapper,
  MenuButton,
  ProfileIconContainer,
  ProfileIconButton,
} from './Header.styled';
import Button from 'common/components/Button/Button';
import { BreadcrumbDiv } from '../styled/Breadcrumbs.styled';
import HeaderBreadcrumbs from './components/HeaderBreadcrumbs';
import { useHeaderTransparentSwitching } from 'hooks/useHeaderTransparentSwitching';

type HeaderProps = {
  isLogoVisible?: boolean;
  toggleHamburger: () => void;
  isArtistPage?: boolean;
};

const transparentHeader = [
  '/library/my-collection',
  `/library/collection`,
  '/create-account',
  '/login/email',
  '/login',
  '/',
  '/reset-password/',
  '/FAQ',
  '/how',
  '/who',
  '/why',
  '/users/confirmation-email',
  '/confirm-email',
  '/users/change-email',
  '/change-email',
  '/remind-password',
  '/labels',
  '/labels-artists',
  '/labels-artists/waitlist',
  '/careers',
  '/join-waitlist',
  '/join-waitlist/thanks',
  '/giveaway',
  '/giveaway/',
  '/giveaway/sxsw',
  '/giveaway/sxsw/',
  '/giveaway/sxsw/thanks',
  '/giveaway/sxsw/thanks/',
  '/giveaway/sxsw/waitlist',
  '/giveaway/sxsw/waitlist/',
  '/artist/insights/users',
  '/company',
];

const transparentHeaderRegex = [
  new RegExp(
    '^/library/collection/[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-4[0-9A-Za-z]{3}-[89ABab][0-9A-Za-z]{3}-[0-9A-Za-z]{12}',
  ),
];

const Header = ({
  isLogoVisible = true,
  toggleHamburger,
  isArtistPage,
}: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewport = useViewport();

  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const isArtist = useStoreState(
    (state) => state.authentication.auth.tokenData?.is_artist || false,
  );
  const visitorLocation = useStoreState(
    (state) => state.authentication.location,
  );
  const isHeaderTransparent = useStoreState(
    (state) => state.style.isHeaderTransparent,
  );
  const showHeader = useStoreState((state) => state.style.showHeader);
  const showFCABanner = false;
  const useTraparentHeaderSwitch = useStoreState(
    (state) => state.style.useTraparentHeaderSwitch,
  );
  useHeaderTransparentSwitching(
    transparentHeader.includes(location.pathname) ||
      transparentHeaderRegex.filter((regEx) => regEx.test(location.pathname))
        .length !== 0 ||
      useTraparentHeaderSwitch,
  );

  const navigateToHandler = (route: string) => {
    navigate(route.replace('Home', ''));
  };

  function showHamburgerButton() {
    return location.pathname == '/';
  }

  function navigateProfileIcon() {
    if (isAuth) {
      navigate('/account/settings');
    } else {
      navigate('/login');
    }
  }

  const renderHeadContent = () => {
    return isAuth ? (
      <InfoSection isArtistPage={isArtistPage}>
        {viewport.width >= 576 ? (
          <>
            <ProfileIconContainer>
              <ProfileIconButton
                label={<ProfileIcon />}
                onClick={navigateProfileIcon}></ProfileIconButton>
            </ProfileIconContainer>
            <BreadcrumbWrapper>
              <HeaderBreadcrumbs isArtistPage={Boolean(isArtistPage)} />
            </BreadcrumbWrapper>
          </>
        ) : (
          <>
            <ProfileIconContainer>
              <ProfileIconButton
                label={<ProfileIcon />}
                onClick={navigateProfileIcon}></ProfileIconButton>
            </ProfileIconContainer>
          </>
        )}
      </InfoSection>
    ) : (
      <InfoSection>
        <ProfileIconContainer>
          <ProfileIconButton
            label={<ProfileIcon />}
            onClick={navigateProfileIcon}></ProfileIconButton>
        </ProfileIconContainer>
        <AccountButtons showButtons={isLogoVisible}>
          <Button
            fontSize={18}
            label="Create Account"
            isTextBold={true}
            onClick={() => navigateToHandler('create-account')}
          />
          <BreadcrumbDiv bold={true}>|</BreadcrumbDiv>
          <Button
            fontSize={18}
            label="Login"
            className="header-login-button"
            isTextBold={false}
            onClick={() => navigateToHandler('login')}
          />
        </AccountButtons>
      </InfoSection>
    );
  };

  return (
    <>
      <Container
        id="header"
        display={showHeader ? 'flex' : 'none'}
        fcaBanner={
          showFCABanner &&
          (visitorLocation === 'GB' ||
            visitorLocation === 'NI' ||
            visitorLocation === 'UK')
        }
        transparent={isHeaderTransparent}>
        {renderHeadContent()}
        {isLogoVisible ? (
          <LogoWrapper isAuth={isAuth}>
            <LogoButton
              label={<LogoSmall />}
              onClick={() => {
                if (isAuth && isArtist) {
                  navigateToHandler('/artist/dashboard');
                } else {
                  navigateToHandler('/');
                }
              }}
            />
          </LogoWrapper>
        ) : null}
        <MenuButton
          className="hamburger-menu-button"
          showHamburger={showHamburgerButton()}
          label={<HamburgerIcon />}
          onClick={toggleHamburger}
        />
      </Container>
    </>
  );
};

const BreadcrumbWrapper = styled.div`
  display: none;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }
`;

export default Header;
