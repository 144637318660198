import React, { FC } from 'react';
import { useViewport } from 'use-viewport';

import {
  CloseButton,
  ModalContainer,
  ModalContentContainer,
  Title,
} from '../Modals/index.styled';



export interface ModalProps {
  onClose?: () => void;
  title?: string;
  padding?: boolean;
  titleBottomMargin?: string;
  children?: React.ReactNode;
}

const ModalLayout: FC<ModalProps> = ({
  title = '',
  children,
  padding,
  titleBottomMargin,
  onClose,
}) => {
  const viewport = useViewport();

  return (
    <ModalContainer padding={padding ? padding : null}>
      {onClose ? <><CloseButton className={'modal-close-button'} onClick={() => onClose()}>X</CloseButton></> : <></>}
      <Title
        bottomMargin={titleBottomMargin}
        text={title}
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz36'}
        letterSpacing="-0.03em"
      />

      <ModalContentContainer
        flexDir="row"
        justifyContent="space-around"
        padding="10px 0 0 0">
        {children}
      </ModalContentContainer>
    </ModalContainer>
  );
};

export default ModalLayout;
