import axios, { handleErrors } from './axios.service';
import {
  BitPurchaseSchema,
  BitExportSchema,
  UpdateFakePurchaseSchema,
  CancelPurchaseSchema,
  PaymentDetails,
} from 'modules/payments/types';
import { ConnectedWalletSchema } from 'modules/payments/types';
import {
  payoutDetailsNonUSA,
  payoutDetailsNonUSA_IBAN,
  payoutDetailsUSA,
  createPayoutDetails,
  dropExport,
  waitlistData,
  Insight,
  Request_AccountingUsers,
  AccountingUser,
  ArtistDashboardData,
  CreateMessageType,
  UpdateMessageType,
  CollectionItemType,
  BenefitPerk,
} from './types';
import { ExportNFTImageType } from 'modules/account/types';
import { ILocationsData } from './types';

class RestService {
  async deleteCard(id: string) {
    try {
      const { data } = await axios({
        url: '/stripe/card/' + id,
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getCardsList(uuid?: string) {
    try {
      let url = '/stripe/card';
      if (uuid) {
        url += `/${uuid}`;
      }
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async makeCardPayment(paymentDetails: PaymentDetails) {
    try {
      const { data } = await axios({
        url: '/stripe/payments/card',
        method: 'post',
        data: paymentDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getPayment(uuid?: string) {
    const url = '/stripe/payments/' + uuid;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getUserBalances() {
    const url = '/circle/balances';
    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async purchaseBit(purchaseDetails: BitPurchaseSchema) {
    try {
      const { data } = await axios({
        url: '/purchase',
        method: 'post',
        data: purchaseDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(error.response.data.error);
      }

      handleErrors(error);
    }
  }

  async sxswGiveaway() {
    try {
      const { data } = await axios({
        url: '/account/giveaway',
        method: 'post',
        data: {},
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(error.response.data.error);
      }

      handleErrors(error);
    }
  }

  async claimGiveway() {
    try {
      const { data } = await axios({
        url: '/purchase/giveaway',
        method: 'post',
        data: {},
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(error.response.data.error);
      }

      handleErrors(error);
    }
  }

  async cancelPurchase(purchaseDetails: CancelPurchaseSchema) {
    try {
      const { data } = await axios({
        url: '/purchase/cancel',
        method: 'post',
        data: purchaseDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async exportBit(exportDetails: BitExportSchema) {
    try {
      const { data } = await axios({
        url: '/purchase/export',
        method: 'post',
        data: exportDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async updateFakePurchase(purchaseDetails: UpdateFakePurchaseSchema) {
    try {
      const { data } = await axios({
        url: '/update',
        method: 'post',
        data: purchaseDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async addOutgoingCryptoAddress(walletDetails: ConnectedWalletSchema) {
    try {
      const { data } = await axios({
        url: '/circle/crypto/outgoing',
        method: 'post',
        data: walletDetails,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async myWallet() {
    const url = '/account/mywallet ';

    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async listTransactions(type?: string) {
    let url = '/transaction';

    if (type) url += `?type=${type}`;

    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async listPayouts() {
    const url = '/transaction?type=withdraw';
    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getTransaction(id: string) {
    const url = '/transaction/' + id;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async confirmEmail(token: string) {
    const url = '/users/confirmation-email/' + token;
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async userMyCollection(userId: string) {
    const url = '/purchase/public-collection/' + userId;
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getMyCollection() {
    const url = '/purchase/collection/';
    try {
      const { data } = await axios.get<CollectionItemType[]>(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getCollectionFromId(id: string) {
    const url = '/purchase/collection?purchaseId=' + id;
    try {
      const { data } = await axios.get<CollectionItemType[]>(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async get2FA() {
    const url = '/account/mfa/setup';
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async post2FA(code_: string) {
    try {
      const { data } = await axios({
        url: '/account/mfa/validate',
        method: 'post',
        data: { code: code_ },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async applePayMerchantValidation(validationUrl: string) {
    try {
      const { data } = await axios({
        url: '/system/applepay/merchant-validation',
        method: 'post',
        data: { validationURL: validationUrl },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async stripePayAppleGoogleToken(
    type: string,
    tokenData: any,
    purchaseId?: any,
  ) {
    try {
      const { data } = await axios({
        url: '/stripe/payments/token',
        method: 'post',
        data: { type: type, purchaseId: purchaseId, ...tokenData },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async remove2FA(key: string) {
    try {
      const { data } = await axios.post('/account/mfa/remove', {
        key,
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getArtistDashboard() {
    try {
      const { data } = await axios.get<ArtistDashboardData>(
        '/artist/dashboard/',
      );

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async setPayoutDetailsUSA(reqData: payoutDetailsUSA) {
    const { data } = await axios.post('/circle/account/usa', reqData);

    return data;
  }

  async setPayoutDetailsNonUSAWithIBAN(reqData: payoutDetailsNonUSA_IBAN) {
    const { data } = await axios.post('/circle/account/non-usa/iban', reqData);

    return data;
  }

  async setPayoutDetailsNonUSA(reqData: payoutDetailsNonUSA) {
    const { data } = await axios.post(
      '/circle/account/non-usa/swift-bic',
      reqData,
    );

    return data;
  }

  async createPayout(reqData: createPayoutDetails) {
    const { data } = await axios.post('/transaction/payout', reqData);

    return data;
  }

  async createPayPalPayout() {
    const reqData = {};
    const { data } = await axios.post('/system/paypal/request-payout', reqData);

    return data;
  }

  async veriff() {
    const reqData = {};
    const { data } = await axios.post('/veriff', reqData);

    return data;
  }

  async getAccountingDashboard() {
    const { data } = await axios.get('/artist/accounting/dashboard');

    return data;
  }

  async getAccountSettings() {
    const { data } = await axios.get('/account/settings');

    return data;
  }

  async getPayouts() {
    const { data } = await axios.get('/account/payouts');

    return data;
  }
  async getDropExport(dates: dropExport) {
    const { data } = await axios.post('/transaction/export', dates, {
      responseType: 'blob',
    });

    return data;
  }
  async waitlist(postData: waitlistData) {
    const { data } = await axios.post('/account/waitlist', postData);
    return data;
  }

  async downloadMP3(purchase_id: string) {
    const url = '/purchase/' + purchase_id + '/download.mp3';
    try {
      const { data } = await axios.get(url, { responseType: 'blob' });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getReleaseSummary(song_id: string) {
    const url = '/artist/release-summary/' + song_id;
    const { data } = await axios.get(url);

    return data;
  }

  async getArtistWaitlistDashboard() {
    const url = '/artist/waitlist-stats';
    const { data } = await axios.get<Insight>(url);

    return data;
  }

  async getInsights() {
    const url = '/artist/insights/';
    const { data } = await axios.get<Insight>(url);

    return data;
  }

  async getInsightsSong(song_id: string) {
    const url = '/artist/insights/' + song_id;
    const { data } = await axios.get<Insight>(url);

    return data;
  }

  async getArtistAccountingUsers(reqData: Request_AccountingUsers) {
    const url = '/artist/users';
    const { data } = await axios.post<AccountingUser[]>(url, reqData);

    return data;
  }

  async downloadNFTImage(purchase_id: string) {
    const url = '/purchase/' + purchase_id + '/unique_image.jpeg';
    try {
      const { data } = await axios.get(url, { responseType: 'blob' });
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async exportNFTImage(exportNFTData: ExportNFTImageType) {
    const url = '/purchase/export-image';
    try {
      const { data } = await axios.post(url, exportNFTData, {
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async registerArtistInterest(artistId: string) {
    const { data } = await axios.post('/account/artist-intrest', {
      id: artistId,
    });
    return data;
  }

  async getInsightCountries(
    requestData: { continent?: string; song_id?: string } = {},
  ) {
    const { data } = await axios.post<ILocationsData[]>(
      '/artist/insights/location/',
      requestData,
    );
    return data;
  }

  // returns list of all emails for the artist
  async getMessageList() {
    const url = '/artist/emails/';
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  // returns single entry for the email
  async getMessage(email_id: string) {
    const url = '/artist/emails/' + email_id;
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getMessagePreview(email_id: string) {
    const url = '/artist/emails/' + email_id + '/preview';
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  // create an email
  async createMessage(messageData: CreateMessageType) {
    try {
      const { data } = await axios.post('/artist/emails/', messageData);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  // updates a single email via the email ID in the URL - only works for draft emails
  async updateMessage(messageID: string, messageData: UpdateMessageType) {
    try {
      const { data } = await axios.patch(
        '/artist/emails/' + messageID,
        messageData,
      );
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async deleteMessage(messageID: string) {
    try {
      const { data } = await axios.delete('/artist/emails/' + messageID);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async sendTestMessage(messageID: string) {
    try {
      const { data } = await axios.post(`/artist/emails/${messageID}/test`);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async getRoyaltyOverview() {
    try {
      const { data } = await axios.get(`/account/royalty/overview`);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async getSongsRoyaltyOverview() {
    const url = '/account/royalty/song-overview/';
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async getSongRoyaltyOverview(slug: string) {
    const url = '/account/royalty/song/' + slug;
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async getRoyaltyHistory(responseType: string, year?: string, song?: string) {
    const url = '/account/royalty/history';
    const postData: any = {};

    if (year) {
      postData['year'] = parseInt(year);
    }
    if (song) {
      postData['song'] = song;
    }
    if (responseType) {
      postData['response_type'] = responseType;
    }
    try {
      const { data } = await axios.post(url, postData);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async getTransactionPerks(transactionId: string) {
    const url = '/transaction/purchase-perks/' + transactionId;
    try {
      const { data } = await axios.get<BenefitPerk[]>(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async songPerks(songId: string) {
    const url = '/purchase/perks/' + songId;
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async connectPayPal() {
    const url = '/system/paypal/oauth';
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async setPayPal(email: string) {
    const url = '/system/paypal/email';

    const postData: any = {
      email: email,
    };

    try {
      const { data } = await axios.post(url, postData);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async checkFCAStatus() {
    const url = '/account/fca';
    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async fcaContactInfo(info: any) {
    try {
      const { data } = await axios.post('/account/fca/contact-info', info);
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async fcaContactInfoAddress(info: any) {
    try {
      const { data } = await axios.post(
        '/account/fca/contact-info/address',
        info,
      );
      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async fcaQuestions() {
    const url = '/account/fca/questions';

    try {
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async submitFcaQuestions(info: any) {
    const url = '/account/fca/questions';

    /*
    {
      "answers": [
          {
              "id": 1,
              "value": true
          },
          {
              "id": 2,
              "value": true
          }
      ]
    }
    */

    try {
      const { data } = await axios.post(url, info);

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async generatePasskeyNonce() {
    const url = '/account/sso/passkeys';

    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async validatePasskeyCreation(payload: any) {
    const url = '/account/sso/passkeys';

    try {
      const { data } = await axios.post(url, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
  async validatePasskeyAuthoriaztion(credentials: any) {
    const url = '/account/sso/passkeys/authorize';

    try {
      const { data } = await axios.post(
        url,
        { credentials },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async artistStripePayout() {
    const url = '/artist/sessions/payout';

    try {
      const { data } = await axios.post(
        url,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async artistStripeManagement() {
    const url = '/artist/sessions/management';

    try {
      const { data } = await axios.post(
        url,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }

  async address(postData: any) {
    const url = '/account/settings/delivery-address';

    try {
      const { data } = await axios.post(url, postData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (error) {
      handleErrors(error);
    }
  }
}

const restService = new RestService();

export default restService;
