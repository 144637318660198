import React, { FC, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'store/store';
import { useNavigate } from 'react-router-dom';
import getPkce from 'oauth-pkce';
import {
  useAuthCallback,
  useEnokiFlow,
  useZkLogin,
  useZkLoginSession,
} from 'songbits-enoki/react';

import { SSOButton, SSOText } from '../../pages/styled/Login.styled';
import theme from 'theme/theme';
import SSOApple from 'common/icons/SSOApple.icon';
import SSOGoogle from 'common/icons/SSOGoogle.icon';
import Facebook from 'common/icons/Facebook.icon';
import Email from 'common/icons/Email.icon';

const generatePKCE = async () => {
  return new Promise((resolve, reject) => {
    getPkce(64, (error, { verifier, challenge }) => {
      if (!error) {
        resolve({ verifier, challenge });
      } else {
        reject(error);
      }
    });
  });
};

interface EnokiSSOProps {
  myAccount?: boolean;
  createAccount?: boolean;
  showButtons: boolean;
  showEmail: boolean;
  onComplete?: () => void;
  dataTestId?: string;
  dataArtistId?: string;
  dataCreateAccount?: boolean;
  dataShowButtons?: boolean;
  dataShowEmail?: boolean;
  dataWaitlistToken?: string;
  dataWaitlistEmail?: string;
}

interface FacebookHash {
  verifier: string;
  challenge: string;
}

const EnokiSSO: FC<EnokiSSOProps> = ({
  myAccount = false,
  showButtons,
  createAccount = false,
  showEmail,
  onComplete,
  dataTestId,
  dataArtistId,
  dataCreateAccount,
  dataShowButtons,
  dataShowEmail,
  dataWaitlistToken,
  dataWaitlistEmail,
}) => {
  const navigate = useNavigate();
  //const [searchParams] = useSearchParams();

  const enokiFlow = useEnokiFlow(); // The EnokiFlow instance
  const { address: suiAddress } = useZkLogin(); // The zkLogin instance

  const userLocation = useStoreState((state) => state.authentication.location);
  const authenticateSSO = useStoreActions(
    (actions) => actions.authentication.authenticateSSO,
  );
  const rest = useStoreActions((actions) => actions.data.rest);
  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [ssoAppleLoading, setSSOAppleLoading] = useState<boolean>(false);
  const [ssoGoogleLoading, setSSOGoogleLoading] = useState<boolean>(false);
  const [ssoFacebookLoading, setSSOFacebookLoading] = useState<boolean>(false);
  const [showFacebook, setShowFacebook] = useState<boolean>(false);

  const { handled } = useAuthCallback(); // This hook will handle the callback from the authentication provider

  const session = useZkLoginSession();

  const generateFacebookHash = async () => {
    //localStorage.setItem("sso_hash_facebook", JSON.stringify(hash));
    return await generatePKCE();
  };

  /*const saveGetParams = () => {
    const artist = searchParams.get('artist');
    const song = searchParams.get('song');
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const params = {artist, song, token, email};
    if (Object.values(params).filter(v => v !== null).length > 0) {
      localStorage.setItem("waitlist_data", JSON.stringify(params));
    }
  }
  const addGetParams = (data: Record<string, any>) => {
    const params = JSON.parse(localStorage.getItem("waitlist_data") || "{}");
    localStorage.removeItem("waitlist_data");
    if (params.artist) data.artist = params.artist;
    if (params.song) data.song = params.song;
    if (params.token) data.token = params.token;
    if (params.email) data.email = params.email;
    return data;
  }*/

  useEffect(() => {
    setShowFacebook(false);
    if (handled) {
      setSSOAppleLoading(false);
      setSSOGoogleLoading(false);
      setSSOFacebookLoading(false);

      const postData: any = session;

      postData.sso_type = localStorage.getItem('sso_type');
      postData.address = suiAddress;
      postData.country = userLocation;

      rest({
        url: '/account/sso/enoki',
        data: postData,
      })
        .then((res: any) => {
          localStorage.removeItem('sso_type');
          authenticateSSO(res).then(() => {
            if (myAccount && onComplete) {
              onComplete();
            } else {
              if (res.tokenData.is_artist) {
                navigate('/artist/dashboard');
              } else {
                navigate('/library/hot-releases');
              }
            }
          });
        })
        .catch((error: any) => {
          const error_message =
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.AuthenticationError
              ? error.response.data.error.AuthenticationError.data
              : 'Upstream Error';

          if (myAccount) {
            navigate('/account/settings', {
              state: { sso_already_exists: error_message },
            });
          } else {
            setGlobalBanner({
              title: 'SSO Error:',
              text: error_message,
            });
            //navigate('/login');
            setTimeout(() => {
              window.location.href = '/login';
            }, 2000);
          }
        });
    }
  }, [handled, session]);

  async function handleSSOAppleSubmit() {
    setSSOAppleLoading(true);
    //saveGetParams();

    let redirectUrl = `${process.env.REACT_APP_REST_URL}/account/sso/enoki/apple/login`;
    if (myAccount) {
      redirectUrl = `${process.env.REACT_APP_REST_URL}/account/sso/enoki/apple/account`;
    }

    localStorage.setItem('sso_type', 'apple');

    const redirect_to = await enokiFlow.createAuthorizationURL({
      provider: 'apple',
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID || '',
      redirectUrl,
      extraParams: {
        scope: ['email', 'name'],
      },
      network: process.env.REACT_APP_ENOKI_NETWORK as
        | 'mainnet'
        | 'testnet'
        | 'devnet'
        | undefined,
    });

    setSSOAppleLoading(false);

    if (process.env.NODE_ENV === 'test') {
      // Simulate the callback directly in test environment
      return;
    }

    window.location.href = redirect_to;
  }

  async function handleSSOGoogleSubmit() {
    setSSOGoogleLoading(true);
    //saveGetParams();

    let redirectUrl = `${window.location.origin}/login`;
    if (myAccount) {
      redirectUrl = `${window.location.origin}/account/settings`;
    }

    localStorage.setItem('sso_type', 'google');

    const redirect_to = await enokiFlow.createAuthorizationURL({
      provider: 'google',
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
      redirectUrl,
      extraParams: {
        scope: ['openid', 'email', 'profile'],
      },
      network: process.env.REACT_APP_ENOKI_NETWORK as
        | 'mainnet'
        | 'testnet'
        | 'devnet'
        | undefined,
    });

    setSSOGoogleLoading(false);
    window.location.href = redirect_to;
  }

  async function handleSSOFacebookSubmit() {
    setSSOFacebookLoading(true);
    //saveGetParams();

    let redirectUrl = `${window.location.origin}/login`;
    if (myAccount) {
      redirectUrl = `${window.location.origin}/account/settings`;
    }

    localStorage.setItem('sso_type', 'facebook');

    const hash = (await generateFacebookHash()) as FacebookHash;

    const redirect_to = await enokiFlow.createAuthorizationURL({
      provider: 'facebook',
      clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID || '',
      redirectUrl,
      extraParams: {
        scope: ['email', 'public_profile'],
        code_challenge: hash.challenge,
      },
      network: process.env.REACT_APP_ENOKI_NETWORK as
        | 'mainnet'
        | 'testnet'
        | 'devnet'
        | undefined,
    });

    setSSOFacebookLoading(false);
    window.location.href = redirect_to;
  }

  async function handleEmailSubmit() {
    navigate('/login/email');
  }

  return (
    <div
      data-testid={dataTestId}
      data-artist-id={dataArtistId}
      data-waitlist-token={dataWaitlistToken ?? ''}
      data-waitlist-email={dataWaitlistEmail ?? ''}
      data-create-account={dataCreateAccount ?? createAccount}
      data-show-buttons={dataShowButtons ?? showButtons}
      data-show-email={dataShowEmail ?? showEmail}>
      {showButtons && (
        <div>
          <SSOButton
            className="apple-login-button"
            height={44}
            width={283}
            borderRadius={10}
            isLoading={ssoAppleLoading}
            disabled={ssoAppleLoading || ssoGoogleLoading || ssoFacebookLoading}
            borderColor={theme.colors.white}
            label={
              <div
                style={{
                  display: 'flex',
                  gap: '23px',
                  textAlign: 'left',
                  verticalAlign: 'middle',
                  alignItems: 'start',
                  justifyContent: 'start',
                }}>
                <SSOApple fill={theme.colors.white} />
                <SSOText
                  text={
                    createAccount
                      ? 'Sign up with Apple'
                      : myAccount
                      ? 'Continue with Apple'
                      : 'Login with Apple'
                  }
                  fontSize="fz16"
                  fontWeight="light"
                  fontColor={theme.colors.white}
                />
              </div>
            }
            onClick={handleSSOAppleSubmit}
          />
          <SSOButton
            className="google-login-button"
            height={44}
            width={283}
            borderRadius={10}
            isLoading={ssoGoogleLoading}
            disabled={ssoGoogleLoading || ssoAppleLoading || ssoFacebookLoading}
            borderColor={theme.colors.white}
            label={
              <div
                style={{
                  display: 'flex',
                  gap: '23px',
                  textAlign: 'left',
                  verticalAlign: 'middle',
                  alignItems: 'start',
                }}>
                <SSOGoogle />
                <SSOText
                  text={
                    createAccount
                      ? 'Sign up with Google'
                      : myAccount
                      ? 'Continue with Google'
                      : 'Login with Google'
                  }
                  fontSize="fz16"
                  fontWeight="light"
                  fontColor={theme.colors.white}
                />
              </div>
            }
            onClick={handleSSOGoogleSubmit}
          />
          {showFacebook && (
            <>
              <SSOButton
                className="facebook-login-button"
                height={44}
                width={283}
                borderRadius={10}
                isLoading={ssoFacebookLoading}
                disabled={
                  ssoGoogleLoading || ssoAppleLoading || ssoFacebookLoading
                }
                borderColor={theme.colors.white}
                label={
                  <div
                    style={{
                      display: 'flex',
                      gap: '23px',
                      textAlign: 'left',
                      verticalAlign: 'middle',
                      alignItems: 'start',
                    }}>
                    <Facebook height={22} width={22} opacity={1} />
                    <SSOText
                      text={
                        createAccount
                          ? 'Sign up with Facebook'
                          : myAccount
                          ? 'Continue with Facebook'
                          : 'Login with Facebook'
                      }
                      fontSize="fz16"
                      fontWeight="light"
                      fontColor={theme.colors.white}
                    />
                  </div>
                }
                onClick={handleSSOFacebookSubmit}
              />
            </>
          )}

          {showEmail && (
            <SSOButton
              className="email-login-button"
              height={44}
              width={283}
              borderRadius={10}
              isLoading={false}
              disabled={false}
              borderColor={theme.colors.white}
              label={
                <div
                  style={{
                    display: 'flex',
                    gap: '23px',
                    textAlign: 'left',
                    verticalAlign: 'middle',
                    alignItems: 'start',
                  }}>
                  <Email height={22} width={22} opacity={1} />
                  <SSOText
                    text={'Login with Email'}
                    fontSize="fz16"
                    fontWeight="light"
                    fontColor={theme.colors.white}
                  />
                </div>
              }
              onClick={handleEmailSubmit}
            />
          )}
        </div>
      )}
    </div>
  );
};

/*EnokiSSO.defaultProps = { 
  myAccount: false,
  createAccount: false
}*/

export default EnokiSSO;
