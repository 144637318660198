import { persist } from 'easy-peasy';

import { authentication } from 'store/models/authModel/authModel';
import { StoreModel } from 'store/models//mainModel.types';
import { balances } from './balancesModel/balancesModel';
import { modals } from './modalModel/modalModel';
import { globalbanner } from './globalModel/globalModel';
import { style } from './styleModel/styleModel';
import { location } from './locationModel/locationModel';
import { purchase } from './purchaseModel/purchaseModel';
import { preview } from './previewModel/previewModel';
import { instructionalModel } from './instructionalModel/instructionalModel';
import { websocket } from './websocketModel/websocketModel';
import { sso } from './ssoModel/ssoModel';
import { data } from './dataModel/dataModel';

const model: StoreModel = {
  authentication: persist(authentication, {
    storage: 'localStorage',
    deny: ['isReady', 'register'],
  }),
  balances,
  modals,
  globalbanner,
  style,
  location,
  purchase: persist(purchase, { storage: 'localStorage' }),
  preview,
  instructions: instructionalModel,
  websocket, //: persist(websocket, {storage: "localStorage"}),
  sso: persist(sso, { storage: 'localStorage' }), // leave in storage for nonce !!
  //data: persist(data, {storage: "localStorage"}),
  data,
};

export default model;
