import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import SBModal from 'common/components/Modal/SBModal';
import ProfileRectangleOutlinedIcon from 'common/icons/ProfileRectangleOutlined.icon';
import ShareIcon from 'common/icons/ShareOutlined.icon';
import EyeWhiteIcon from 'common/icons/MyCollection/EyeWhite.icon';
import ChainRectangleOutlinedIcon from 'common/icons/MyCollection/ChainRectangleOutlined.icon';

import NFTOptionsModal from '../MyCollection/NFTOptionsModal';

import SongProfileShareModal from '../../../../library/pages/components/SongProfileShareModal/SongProfileShareModal';
import ImagePlayer from './ImagePlayer/ImagePlayer';
import DefaultButton from 'common/components/DefautButton/DefaultButton';
import PurchaseInfoModal from '../Modals/PurchaseInfoModal/PurchaseInfoModal';

import NFTModal from 'modules/library/pages/components/Modals/NFTModal/NFTModal';

import { NFTOptionsModalType } from 'modules/account/types';

import { TransactionType } from 'modules/library/types';
import { sm } from 'utils/constants';

type ArtistCardProps = {
  songData: TransactionType;
  isPlaying: boolean;
  onPlay: () => void;
  onPause: () => void;
  reloadAPI: () => void;
};

const MyCollectionCard = ({
  songData,
  isPlaying,
  onPause,
  onPlay,
}: ArtistCardProps) => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const [showPurchaseInfo, setShowPurchaseInfo] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showNFTModal, setShowNFTModal] = useState<boolean>(false);

  const [socialTopPosition, setSocialTopPosition] = useState<string>('40%');

  const [showNFTOptionsModal, setShowNFTOptionsModal] = useState<{
    show: boolean;
    type: NFTOptionsModalType;
  }>({
    show: false,
    type: 'export',
  });

  return (
    <>
      <CardContainer
        className="cardd"
        id={`collection-item-${songData.originaltransactionid}`}>
        <ImagePlayer
          charity={songData.artistslug === 'bigissue'}
          imageSrc={songData.file_url}
          isPlaying={isPlaying}
          onPause={onPause}
          onPlay={onPlay}
        />

        <SongDetails>
          <SongDescriptionContainer>
            <TextTruncated
              text={songData.title}
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              fontSize={viewport.width < sm ? 'fz18' : 'fz24'}
            />
            <TextTruncated
              text={songData.artist_name}
              fontWeight="light"
              fontSize={viewport.width < sm ? 'fz14' : 'fz18'}
              lineHeight="20px"
            />
          </SongDescriptionContainer>

          <TextButton
            className="purchase-info-button view-transaction-details-button"
            onClick={() => setShowPurchaseInfo(true)}>
            <EyeIconContainer>
              <EyeWhiteIcon
                width={viewport.width < sm ? 14 : 28}
                height={viewport.width < sm ? 10 : 20}
                fill={viewport.width < sm ? 'white' : '#ffffff80'}
              />
            </EyeIconContainer>
            <Typography
              text={
                (viewport.width < sm ? 'reveal' : 'view') +
                ' transaction details'
              }
              fontSize={viewport.width < sm ? 'fz12' : 'fz16'}
            />
          </TextButton>

          <TextButton
            className="view-inclusive-benefits-button"
            onClick={() => {
              /*setNavigateState({
                id: songData.originaltransactionid,
              });*/
              navigate(
                `/library/my-collection/${songData.artistslug}/${songData.songslug}/benefits`,
                {
                  state: {
                    id: songData.originaltransactionid,
                  },
                },
              );
            }}>
            <Typography
              text={'view inclusive benefits'}
              fontSize="fz16"
              fontWeight="bold"
            />
          </TextButton>
        </SongDetails>

        <IconsContainer>
          {songData.artistslug !== 'bigissue' && (
            <DefaultButton
              className="show-nft-options-modal-button"
              onClick={() =>
                setShowNFTOptionsModal({
                  show: true,
                  type: 'export',
                })
              }>
              <ChainRectangleOutlinedIcon
                width={viewport.width < sm ? 20 : 25}
                height={viewport.width < sm ? 20 : 25}
              />
            </DefaultButton>
          )}
          <DefaultButton
            className="share-button show-share-modal-button"
            onClick={() => setShowShareModal(true)}>
            <ShareIcon
              width={viewport.width < sm ? 20 : 25}
              height={viewport.width < sm ? 20 : 25}
            />
          </DefaultButton>
          {songData.nft_image_available ? (
            <>
              <DefaultButton
                onClick={() => {
                  setShowNFTModal(true);
                }}
                className="show-nft-image-options-modal-button">
                <ProfileRectangleOutlinedIcon
                  width={viewport.width < sm ? 27 : 28}
                  height={viewport.width < sm ? 24 : 25}
                />
              </DefaultButton>
            </>
          ) : (
            <>
              {' '}
              <DefaultButton onClick={() => {}}></DefaultButton>
            </>
          )}
        </IconsContainer>
      </CardContainer>

      <SBModal
        className={'purchase-info'}
        isOpen={showPurchaseInfo}
        height={'430px'}
        width="360px"
        top="30%"
        content={
          <PurchaseInfoModal
            songData={songData}
            onClose={() => setShowPurchaseInfo(false)}
          />
        }
      />

      <SBModal
        className={'share'}
        isOpen={showShareModal}
        height={'430px'}
        width="434px"
        top={socialTopPosition}
        content={
          <SongProfileShareModal
            shareUrl={`/${songData.artistslug}/${songData.songslug}`}
            imageLink={songData.file_url}
            onChange={() => {
              setShowShareModal(false);
            }}
            changeModalPosition={(topPos) => {
              setSocialTopPosition(topPos);
            }}
          />
        }
      />

      <NFTModal
        show={showNFTModal}
        songData={songData}
        onClose={() => setShowNFTModal(false)}
        onChainClick={() => {
          setShowNFTOptionsModal({
            show: true,
            type: 'image',
          });
        }}
      />

      <SBModal
        className={'nft-options'}
        isOpen={showNFTOptionsModal.show}
        height="553px"
        width="460px"
        top="30%"
        content={
          <NFTOptionsModal
            type={showNFTOptionsModal.type}
            onChange={() => {
              setShowNFTOptionsModal({
                show: false,
                type: 'export',
              });
            }}
            nft_link={
              songData.metadata_link
                ? songData.metadata_link
                : songData.nft_link
            }
            image_nft_link={
              songData.metadata_image_link
                ? songData.metadata_image_link
                : songData.nft_image_link
            }
            purchaseId={songData.originaltransactionid}
            fileName={songData.title + ' - ' + songData.artist_name}
            nftTokenId={songData.nfttokenid}
            nftImageTokenId={songData.nftimagetokenid}
            nft_image_available={songData.nft_image_available}
          />
        }
      />
    </>
  );
};

const CardContainer = styled.div<{ id?: string }>`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SongDetails = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  margin-right: 20px;

  @media (min-width: ${sm}px) {
    min-height: 150px;
    justify-content: initial;
    gap: 10px;
  }
`;

const SongDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: ${sm}px) {
    gap: 10px;

    margin-bottom: auto;
  }
`;

const EyeIconContainer = styled.div`
  padding-top: 2px;
  @media (min-width: ${sm}px) {
    margin-right: 5px;
    padding-top: 4px;
  }
`;

const TextTruncated = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextButton = styled(DefaultButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const IconsContainer = styled.div`
  height: 100%;
  min-height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${sm}px) {
    min-height: 150px;
  }
`;

export const ModalHeadingText = styled(Typography)``;

export const Text = styled(Typography)<{
  paddingRight?: number;
  red?: boolean;
  inline?: boolean;
}>`
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : 0)}px;
  line-height: 24px;
`;

export const ModalCloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const SubmitButton = styled(Button)`
  margin: 0 auto;
`;

export const BoldButtonText = styled(Typography)``;

export const SubmitButtonContent = styled.div<{ justifyContent?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

export default MyCollectionCard;
