import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import {
  useStoreActions,
  useStoreState,
  useStoreRehydrated,
} from 'store/store';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';
import { setIsReloaded } from 'store-persist/reducers/modalSlice';
import { resetPersist } from 'store-persist/reducers/modalSlice';

import WebHelmet from 'common/components/GlobalHelmet/GlobalHelmet';
import Loader from 'common/components/Loader/Loader';

import ToasterContainer from 'common/components/Toaster/Toaster';
import GlobalBanner from 'common/layout/GlobalBanner';
import Routes from './Routes';
import SystemServices from 'common/components/SystemServices/SystemServices';
import SSORequiredModal from 'common/components/Enoki/SSORequired';

const INTERCOM_APP_ID = 'yhh9c702';

function App() {
  const isRehydrated = useStoreRehydrated();
  //return isRehydrated ? <Main /> : <div>Loading...</div>;
  const isReady = useStoreState((state) => state.authentication.isReady);
  const suiEnabled = useStoreState(
    (state) => state.authentication.auth.suiEnabled,
  );
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );
  const dispatch = useAppDispatch();
  const { modalType, expiryTime } = useAppSelector((state) => state.modalSlice);
  const isArtist = useStoreState(
    (state) => state.authentication.auth.tokenData?.is_artist || false,
  );
  const { setLocation } = useStoreActions((state) => state.authentication);

  const rest = useStoreActions((actions) => actions.data.rest);

  useEffect(() => {
    if (modalType !== 'none') {
      dispatch(setIsReloaded(true));
    }
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      window.localStorage.removeItem('purchaseId');
      dispatch(resetPersist());
    }
  }, []);

  useEffect(() => {
    //Get user country
    if (isReady) {
      rest({
        url: `/system/ip-lookup`,
        model: 'ip_lookup',
      })
        .then((countryResponse: any) => {
          if (countryResponse) {
            if (countryResponse.country !== 'Z') {
              setLocation(countryResponse.country);
            } else {
              // Z - unknown country
              setLocation('');
            }
          }
        })
        .catch(() => {
          setLocation('');
        });
    }
  }, [isReady]);

  const showBanner = useStoreState(
    (state) => state.globalbanner.show_global_banner,
  );

  return isRehydrated ? (
    <>
      <SystemServices />
      <WebHelmet title="SongBits" />
      {isReady ? (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
          <Router>
            <Routes />
            <ToasterContainer />
            {showBanner ? <GlobalBanner /> : ''}
            <SSORequiredModal
              show={!suiEnabled && isAuthenticated && !isArtist}
            />
          </Router>
        </IntercomProvider>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  ) : (
    <></>
  );
}

export default App;
