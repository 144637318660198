import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';

import { createRoot } from 'react-dom/client';

import store from 'store/store';
import theme from 'theme/theme';

import App from './App';
import client from './graphql/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './reset.css';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistor, storePersist } from 'store-persist/configureStore';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { EnokiFlowProvider } from 'songbits-enoki/react';
import { SuiClientProvider } from '@mysten/dapp-kit';
import { getFullnodeUrl } from '@mysten/sui/client';
import type { SuiClientOptions } from '@mysten/sui/client';
Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
});

const getLibrary = (provider: any) => {
  return new Web3(provider);
};

const networks = {
  localnet: { url: getFullnodeUrl('localnet') },
  testnet: { url: getFullnodeUrl('testnet') },
  mainnet: { url: getFullnodeUrl('mainnet') },
} satisfies Record<string, SuiClientOptions>;

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  //<React.StrictMode>
  <SuiClientProvider networks={networks} defaultNetwork="localnet">
    <EnokiFlowProvider apiKey={process.env.REACT_APP_ENOKI_API_KEY as string}>
      <StoreProvider store={store}>
        <Provider store={storePersist}>
          <PersistGate loading={null} persistor={persistor}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </ApolloProvider>
            </Web3ReactProvider>
          </PersistGate>
        </Provider>
      </StoreProvider>
    </EnokiFlowProvider>
  </SuiClientProvider>,
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
